import React, { useState, useEffect } from "react"
import { Container, Row, Col, Button } from "reactstrap"
import PropertiesTable from "./PropertiesTable"
import { getActiveProperties } from "../../helpers/apiRepository"
import i18n from "../../i18n"
import { useQuery } from "react-query"
import MetaTags from "react-meta-tags"
import "./Properties.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

const Properties = props => {
  const properties = useQuery("myProperties", getActiveProperties)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{i18n.t("Properties | Brily")} ;)</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={i18n.t("Properties")}
            breadcrumbItem={i18n.t("All")}
          />
          <Row>
            <Col className="col-10"></Col>
            <Col className="col-2" style={{ textAlign: "right" }}>
              <Button
                type="button"
                color="primary"
                size="sm"
                className="btn-rounded waves-effect waves-light action-button"
                style={{ marginBottom: 12, marginTop: 2 }}
                href={`/property/create`}
              >
                {i18n.t("Add New Property")}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <PropertiesTable properties={properties} activeOnly={true} />
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <PropertiesTable properties={properties} activeOnly={false} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Properties)
