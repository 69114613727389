import React from "react"
import "./leads.css"
import MetaTags from "react-meta-tags"
import { Container, Row, Col } from "reactstrap"
import LeadTable from "./LeadTable"
import { getAllLeads } from "../../helpers/apiRepository"
import { useQuery } from "react-query"
import i18n from "../../i18n"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

function Leads() { 
  const leads = useQuery("allLeads", getAllLeads)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{i18n.t("Leads | Brily")} ;)</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={i18n.t("Leads")} breadcrumbItem={i18n.t("All")} />
          <Row>
            <Col xl="12">
              <LeadTable leads={leads} activeOnly={true} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Leads
