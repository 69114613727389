import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap"

import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { useParams, withRouter, matchPath } from "react-router-dom"
import { getProperty } from "../../api"
import TranslationInputPair from "../../components/Common/TranslationInputPair"
import TranslationTextAreaPair from "../../components/Common/TranslationTextAreaPair"
import i18n from "../../i18n"
import ImageUploaderMultiple from "../../components/Common/ImageUploaderMultiple/ImageUploaderMultiple"
import PropertyFeatures from "../../components/Common/PropertyFeatures/PropertyFeatures"
import { FaUpload } from "react-icons/fa"
import "./EditProperty.css"
import { useMutation, useQueryClient } from "react-query"
import axios from "axios"
import { toast } from "react-toastify"
import validator from "helpers/validator"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import GoogleMap from "../../components/GoogleMap/map"

const EditProperty = props => {
  const [propertyName, setPropertyName] = useState("")
  const [propertyActive, setPropertyActive] = useState(false)
  const [propertyHide, setPropertyHide] = useState(false)
  const [propertyPrice, setPropertyPrice] = useState(0)
  const [propertyYear, setPropertyYear] = useState("")
  const [propertyBeds, setPropertyBeds] = useState("")
  const [propertyBaths, setPropertyBaths] = useState("")
  const [propertyArea, setPropertyArea] = useState(0)
  const [propertyPlot, setPropertyPlot] = useState(0)

  const [videoUrl, setVideoUrl] = useState("")
  const [videoProvider, setVideoProvider] = useState(i18n.t("Select Provider"))
  const [isVideoDropdownOpen, setIsVideoDropdownOpen] = useState(false)

  const [introTitleEn, setIntroTitleEn] = useState("")
  const [introTitleEs, setIntroTitleEs] = useState("")

  const [introContentEn, setIntroContentEn] = useState("")
  const [introContentEs, setIntroContentEs] = useState("")

  const [introSubtitleEn, setIntroSubtitleEn] = useState("")
  const [introSubtitleEs, setIntroSubtitleEs] = useState("")

  const [descriptionTitleEn, setDescriptionTitleEn] = useState("")
  const [descriptionTitleEs, setDescriptionTitleEs] = useState("")

  const [descriptionContentEn, setDescriptionContentEn] = useState("")
  const [descriptionContentEs, setDescriptionContentEs] = useState("")

  const [descriptionSubtitleEn, setDescriptionSubtitleEn] = useState("")
  const [descriptionSubtitleEs, setDescriptionSubtitleEs] = useState("")

  const [locationTitleEn, setLocationTitleEn] = useState("")
  const [locationTitleEs, setLocationTitleEs] = useState("")

  const [locationContentEn, setLocationContentEn] = useState("")
  const [locationContentEs, setLocationContentEs] = useState("")

  const [mapAddress, setMapAddress] = useState("")
  const [lat, setLat] = useState("")
  const [lng, setLng] = useState("")

  const [locationDescEn, setLocationDescEn] = useState("")
  const [locationDescEs, setLocationDescEs] = useState("")

  const [metaTitleEn, setMetaTitleEn] = useState("")
  const [metaTitleEs, setMetaTitleEs] = useState("")

  const [metaDescriptionEn, setMetaDescriptionEn] = useState("")
  const [metaDescriptionEs, setMetaDescriptionEs] = useState("")

  const [locationSubtitleEn, setLocationSubtitleEn] = useState("")
  const [locationSubtitleEs, setLocationSubtitleEs] = useState("")
  const [floorPlanFile, setFloorPlanFile] = useState("")

  const [images, setImages] = useState([])
  const [center, setCenter] = useState(false)
  const [features, setFeatures] = useState([])

  const match = matchPath(props.location.pathname, {
    path: "/property/edit/:id",
    exact: true,
    strict: false,
  })

  const updateFeatures = features => {}
 
  const propertyId = match.params.id

  const propertyQuery = useQuery(["property", propertyId], () =>
    getProperty(propertyId)
  )

  useEffect(() => {
    if (
      propertyQuery &&
      propertyQuery != undefined &&
      propertyQuery.isSuccess &&
      propertyQuery.data
    ) {
      console.log(propertyQuery.data)
      setPropertyName(propertyQuery.data.descriptions[0].name)
      setPropertyActive(propertyQuery.data.status == 1)
      setPropertyHide(propertyQuery.data.status == 3)
      setPropertyYear(propertyQuery.data.yearBuilt)
      setPropertyPrice(propertyQuery.data.price)
      setPropertyBaths(propertyQuery.data.baths)
      setPropertyBeds(propertyQuery.data.beds)
      setPropertyArea(propertyQuery.data.areaM)
      setPropertyPlot(propertyQuery.data.plot)
      setVideoUrl(propertyQuery.data.videoPath)
      setVideoProvider(propertyQuery.data.videoProvider)

      // TODO: Select the right language (Eventually we will add a language dropdown for all languages)

      setIntroTitleEn(
        propertyQuery.data.descriptions[0]
          ? propertyQuery.data.descriptions[0].introTitle
          : ""
      )
      setIntroTitleEs(
        propertyQuery.data.descriptions[1]
          ? propertyQuery.data.descriptions[1].introTitle
          : ""
      )

      setIntroContentEn(
        propertyQuery.data.descriptions[0]
          ? propertyQuery.data.descriptions[0].introContent
          : ""
      )
      setIntroContentEs(
        propertyQuery.data.descriptions[1]
          ? propertyQuery.data.descriptions[1].introContent
          : ""
      )

      setIntroSubtitleEn(
        propertyQuery.data.descriptions[0]
          ? propertyQuery.data.descriptions[0].introSubtitle
          : ""
      )
      setIntroSubtitleEs(
        propertyQuery.data.descriptions[1]
          ? propertyQuery.data.descriptions[1].introSubtitle
          : ""
      )
      setMetaTitleEn(
        propertyQuery.data.descriptions[0]
          ? propertyQuery.data.descriptions[0].metaTitle
          : ""
      )
      setMetaTitleEs(
        propertyQuery.data.descriptions[1]
          ? propertyQuery.data.descriptions[1].metaTitle
          : ""
      )
      setMetaDescriptionEn(
        propertyQuery.data.descriptions[0]
          ? propertyQuery.data.descriptions[0].metaDescription
          : ""
      )
      setMetaDescriptionEs(
        propertyQuery.data.descriptions[1]
          ? propertyQuery.data.descriptions[1].metaDescription
          : ""
      )

      setDescriptionTitleEn(
        propertyQuery.data.descriptions[0]
          ? propertyQuery.data.descriptions[0].descriptionTitle
          : ""
      )
      setDescriptionTitleEs(
        propertyQuery.data.descriptions[1]
          ? propertyQuery.data.descriptions[1].descriptionTitle
          : ""
      )

      setDescriptionContentEn(
        propertyQuery.data.descriptions[0]
          ? propertyQuery.data.descriptions[0].descriptionContent
          : ""
      )
      setDescriptionContentEs(
        propertyQuery.data.descriptions[1]
          ? propertyQuery.data.descriptions[1].descriptionContent
          : ""
      )

      setDescriptionSubtitleEn(
        propertyQuery.data.descriptions[0]
          ? propertyQuery.data.descriptions[0].descriptionSubtitle
          : ""
      )
      setDescriptionSubtitleEs(
        propertyQuery.data.descriptions[1]
          ? propertyQuery.data.descriptions[1].descriptionSubtitle
          : ""
      )

      setLocationTitleEn(
        propertyQuery.data.descriptions[0]
          ? propertyQuery.data.descriptions[0].locationTitle
          : ""
      )
      setLocationTitleEs(
        propertyQuery.data.descriptions[1]
          ? propertyQuery.data.descriptions[1].locationTitle
          : ""
      )

      setLocationContentEn(
        propertyQuery.data.descriptions[0]
          ? propertyQuery.data.descriptions[0].locationContent
          : ""
      )
      setLocationContentEs(
        propertyQuery.data.descriptions[1]
          ? propertyQuery.data.descriptions[1].locationContent
          : ""
      )
      setLocationDescEn(
        propertyQuery.data.descriptions[0]
          ? propertyQuery.data.descriptions[0].locationDescription
          : ""
      )
      setLocationDescEs(
        propertyQuery.data.descriptions[1]
          ? propertyQuery.data.descriptions[1].locationDescription
          : ""
      )

      setLocationSubtitleEn(
        propertyQuery.data.descriptions[0]
          ? propertyQuery.data.descriptions[0].locationSubtitle
          : ""
      )
      setLocationSubtitleEs(
        propertyQuery.data.descriptions[1]
          ? propertyQuery.data.descriptions[1].locationSubtitle
          : ""
      )
      setCenter({
        lat: propertyQuery.data.latitude ? propertyQuery.data.latitude : "",
        lng: propertyQuery.data.longitude ? propertyQuery.data.longitude : "",
      })
      setMapAddress(
        propertyQuery.data.mapAddress ? propertyQuery.data.mapAddress : ""
      )
      console.log(propertyQuery.data.PropertyFeatures);
      setFeatures(
        propertyQuery.data.PropertyFeatures
          ? propertyQuery.data.PropertyFeatures.map(item => {
              return {
                category: item.Feature.Category,
                feature: item.Feature,
                id: item.id
              }
            })
          : ""
      )

      setReferenceVal(
        propertyQuery.data.reference ? propertyQuery.data.reference : ""
      )
      setSelectedStoryTemplate(
        propertyQuery.data.storyTemplate ? propertyQuery.data.storyTemplate : ""
      )
      setSelectedPropertyTemplate(
        propertyQuery.data.propertyTemplate
          ? propertyQuery.data.propertyTemplate
          : ""
      )
      // Prepopulate the images array with type: local to render images in component
      setImages(
        propertyQuery.data.images.map(img => {
          return {
            source: `${img}`,
            options: { type: "local" },
            file: {
              name: `${process.env.REACT_APP_API_ROOT}/uploads/${img}`,
              size: 3001025,
              type: "image/*",
            },
          }
        })
      )
      console.log(propertyQuery.data)
      // setFloorPlanFile({
      //   source: `${propertyQuery.data.floorPlan}`,
      //   options: { type: "local" },
      //   file: {
      //     name: `${process.env.REACT_APP_API_ROOT}/uploads/${propertyQuery.data.floorPlan}`,
      //     size: 3001025,
      //     type: "application/pdf",
      //   },
      // })
      setFloorPlanFile(propertyQuery.data.floorPlan)
    }
  }, [propertyQuery.isSuccess])

  const [selectedStoryTemplate, setSelectedStoryTemplate] = useState(
    i18n.t("Select Story Template")
  )
  const [selectedPropertyTemplate, setSelectedPropertyTemplate] = useState(
    i18n.t("Select Property Template")
  )
  const [storyTemp, setStoryTemp] = useState(false)
  const [propertyTemp, setPropertyTemp] = useState(false)
  const [referenceVal, setReferenceVal] = useState("")
  const storyTemplates = ["Template 1"]

  const PropertyTemplates = ["Template 1"]

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    })
  }, [])

  var token = JSON.parse(localStorage.getItem("authUser")).token
  const mutation = useMutation(body => {
    return axios.post(
      `${process.env.REACT_APP_API_ROOT}/property/update`,
      body,
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      }
    )
  })

  const submit = e => {
    e.preventDefault()

    let featuresList = features.map(item => {
      return { featureId: item.feature.id,id: item.id }
    })

    let details = {
      id: propertyQuery.data.id,
      propertyName,
      status: 1,
      price: propertyPrice,
      yearBuilt: propertyYear,
      beds: propertyBeds,
      baths: propertyBaths,
      areaM: propertyArea,
      plot: propertyPlot,
      floorPlan: floorPlanFile,
      videoPath: videoUrl,
      videoProvider: videoProvider,
      mapAddress: mapAddress,
      longitude: center.lng,
      latitude: center.lat,
      noIndex: false,
      // Description En Starts
      descriptionEnId: propertyQuery.data.descriptions[0].id,
      langEn: "en",
      nameEn: propertyName,
      locationDescriptionEn: locationDescEn,
      introTitleEn: introTitleEn,
      introSubtitleEn: introSubtitleEn,
      introContentEn: introContentEn,
      descriptionTitleEn: descriptionTitleEn,
      descriptionSubtitleEn: descriptionSubtitleEn,
      descriptionContentEn: descriptionContentEn,
      locationTitleEn: locationTitleEn,
      locationSubtitleEn: locationSubtitleEn,
      locationContentEn: locationContentEn,
      metaTitleEn: metaTitleEn,
      metaDescriptionEn: metaDescriptionEn,
      // Description Es Starts
      descriptionEsId: propertyQuery.data.descriptions[1]
        ? propertyQuery.data.descriptions[1].id
        : propertyQuery.data.descriptions[0].id,
      langEs: "es",
      nameEs: propertyName,
      locationDescriptionEs: locationDescEn,
      introTitleEs: introTitleEs,
      introSubtitleEs: introSubtitleEs,
      introContentEs: introContentEs,
      descriptionTitleEs: descriptionTitleEs,
      descriptionSubtitleEs: descriptionSubtitleEs,
      descriptionContentEs: descriptionContentEs,
      locationTitleEs: locationTitleEs,
      locationSubtitleEs: locationSubtitleEs,
      locationContentEs: locationContentEs,
      metaTitleEs: metaTitleEs,
      metaDescriptionEs: metaDescriptionEs,
      reference: referenceVal,
      storyTemplate: selectedStoryTemplate,
      propertyTemplate: selectedPropertyTemplate,
      images,
      PropertyFeatures: featuresList,
      previousImage: [propertyQuery.data.images.map(img => img)],
    }
    let formData = new FormData()

    for (var item in details) {
      if (item == "images") {
        details[item].forEach((val, key) => {
          formData.append(`${key}[]`, val)
        })
      } else if (item == "PropertyFeatures") {
        formData.append(item, JSON.stringify(details[item]))
      } else {
        formData.append(item, details[item])
      }
    }

    mutation.mutate(formData, {
      onSuccess: data => {
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      },
      onError: data => {
        toast.error(
          "Reference Field should be unique OR Check if Any empty field",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        )
      },
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{i18n.t("Edit Property | Brily")} ;)</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={i18n.t("Properties")}
            breadcrumbItem={i18n.t("Edit Property")}
          />

          {propertyQuery.data && (
            <>
              <Row>
                <Col lg={6}>
                  <Card>
                    <CardBody style={{ display: "flex" }}>
                      <h3 style={{ marginTop: 5, marginBottom: 5 }}>
                        {propertyQuery.data.descriptions[0].name}
                      </h3>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card style={{ display: "flex" }}>
                    <CardBody style={{ alignItems: "end", display: "flex" }}>
                      <Button
                        color="danger"
                        style={{ flex: 1, marginLeft: 5, marginRight: 5 }}
                        // onClick={}
                      >
                        {i18n.t("Cancel")}
                      </Button>
                      <Button
                        color="success"
                        style={{ flex: 1, marginLeft: 5, marginRight: 5 }}
                        onClick={submit}
                      >
                        {i18n.t("Update Property")}
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        {i18n.t("Property Specification")}
                      </CardTitle>

                      <Form>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            {i18n.t("Name")}
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            value={propertyName}
                            onChange={e => setPropertyName(e.target.value)}
                          />
                        </div>

                        <Row>
                          <Col md={6}>
                            <div className="form-check">
                              <Input
                                type="checkbox"
                                className="form-check-Input"
                                id="formrow-customCheck"
                                checked={propertyActive}
                                onChange={e =>
                                  setPropertyActive(e.target.value)
                                }
                              />
                              <Label
                                className="form-check-Label"
                                htmlFor="formrow-customCheck"
                              >
                                {i18n.t("Active")}
                              </Label>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form-check">
                              <Input
                                type="checkbox"
                                className="form-check-Input"
                                id="formrow-customCheck"
                                checked={propertyHide}
                                onChange={e => setPropertyHide(e.target.value)}
                              />
                              <Label
                                className="form-check-Label"
                                htmlFor="formrow-customCheck"
                              >
                                {i18n.t("Hide From Index")}
                              </Label>
                            </div>
                          </Col>
                        </Row>

                        <div className="mb-3"></div>

                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-email-Input">
                                {i18n.t("Price")}
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="formrow-email-Input"
                                value={propertyPrice}
                                onChange={e => setPropertyPrice(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-password-Input">
                                {i18n.t("Year Built")}
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="formrow-password-Input"
                                value={propertyYear}
                                onChange={e => setPropertyYear(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={2}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-InputZip">
                                {i18n.t("Beds")}
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="formrow-InputZip"
                                value={propertyBeds}
                                onChange={e => setPropertyBeds(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-InputZip">
                                {i18n.t("Baths")}
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-InputZip"
                                value={propertyBaths}
                                onChange={e => setPropertyBaths(e.target.value)}
                              />
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-InputCity">
                                {i18n.t("Total Area (m2)")}
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="formrow-InputCity"
                                value={propertyArea}
                                onChange={e => setPropertyArea(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-InputState">
                                {i18n.t("Plot Size (m2)")}
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="formrow-InputCity"
                                value={propertyPlot}
                                onChange={e => setPropertyPlot(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-password-Input">
                                {i18n.t("Video Provider")}
                              </Label>
                              <br />
                              <ButtonDropdown
                                isOpen={isVideoDropdownOpen}
                                toggle={() =>
                                  setIsVideoDropdownOpen(!isVideoDropdownOpen)
                                }
                                onClick={e => e.preventDefault()}
                              >
                                <Button id="caret" color="secondary">
                                  {videoProvider}
                                </Button>
                                <DropdownToggle caret color="secondary">
                                  <i className="mdi mdi-chevron-down" />
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={e => setVideoProvider("vimeo")}
                                  >
                                    {i18n.t("vimeo")}
                                  </DropdownItem>
                                </DropdownMenu>
                              </ButtonDropdown>
                            </div>
                          </Col>
                          <Col md={9}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-password-Input">
                                {i18n.t("Video URL")}
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-password-Input"
                                value={videoUrl}
                                onChange={e => {
                                  setVideoUrl(e.target.value)
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg={6}>
                  <Card>
                    <CardBody style={{ height: "100%" }}>
                      <CardTitle className="mb-4">
                        {i18n.t("Features")}
                      </CardTitle>
                      <PropertyFeatures 
                        features={features}
                        updateFeatures={setFeatures}
                        fromEdit={false}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        {i18n.t("Template")}
                      </CardTitle>

                      <Form>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-email-Input">
                                {i18n.t("Story Template")}
                              </Label>
                              <div>
                                <ButtonDropdown
                                  style={{ width: "100%" }}
                                  isOpen={storyTemp}
                                  toggle={() => setStoryTemp(!storyTemp)}
                                  onClick={e => e.preventDefault()}
                                >
                                  <Button id="caret" color="secondary">
                                    {selectedStoryTemplate}
                                  </Button>
                                  <DropdownToggle caret color="secondary">
                                    <i className="mdi mdi-chevron-down" />
                                  </DropdownToggle>
                                  <DropdownMenu style={{ width: "100%" }}>
                                    <DropdownItem>
                                      {i18n.t("Story Template")}
                                    </DropdownItem>
                                    {storyTemplates.map((item, key) => (
                                      <DropdownItem
                                        key={key}
                                        onClick={() => {
                                          setSelectedStoryTemplate(item)
                                        }}
                                      >
                                        {item}
                                      </DropdownItem>
                                    ))}
                                  </DropdownMenu>
                                </ButtonDropdown>
                              </div>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-email-Input">
                                {i18n.t("Property Template")}
                              </Label>
                              <div>
                                <ButtonDropdown
                                  style={{ width: "100%" }}
                                  isOpen={propertyTemp}
                                  toggle={() => setPropertyTemp(!propertyTemp)}
                                  onClick={e => e.preventDefault()}
                                >
                                  <Button id="caret" color="secondary">
                                    {selectedPropertyTemplate}
                                  </Button>
                                  <DropdownToggle caret color="secondary">
                                    <i className="mdi mdi-chevron-down" />
                                  </DropdownToggle>
                                  <DropdownMenu style={{ width: "100%" }}>
                                    <DropdownItem>
                                      {i18n.t("Select Property Template")}
                                    </DropdownItem>
                                    {PropertyTemplates.map((item, key) => (
                                      <DropdownItem
                                        key={key}
                                        onClick={() => {
                                          setSelectedPropertyTemplate(item)
                                        }}
                                      >
                                        {item}
                                      </DropdownItem>
                                    ))}
                                  </DropdownMenu>
                                </ButtonDropdown>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={12}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-InputZip">
                                {i18n.t("Reference")}
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-InputZip"
                                value={referenceVal}
                                placeholder={i18n.t("20 character limits")}
                                onChange={e => {
                                  if (referenceVal.length <= 20) {
                                    setReferenceVal(e.target.value)
                                  }
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card>
                    <CardBody style={{ height: "100%" }}>
                      <CardTitle className="mb-4">
                        {i18n.t("Floor Plan")}
                      </CardTitle>
                      <Row>
                        <Col
                          sm={4}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: 16,
                          }}
                        >
                          {i18n.t("PDF File Only")}
                        </Col>
                        <Col sm={6}>
                          <Button
                            style={{
                              flex: 1,
                              marginLeft: 5,
                              marginRight: 5,
                              fontSize: 18,
                              padding: 0,
                            }}
                            color="info"
                          >
                            <Label
                              for="uploadFloorPlan"
                              style={{
                                marginBottom: 0,
                                display: "flex",
                                alignItems: "center",
                                padding: 8,
                                cursor: "pointer",
                              }}
                            >
                              <FaUpload size={20} style={{ marginRight: 10 }} />{" "}
                              {floorPlanFile
                                ? i18n.t("Uploaded Successfully")
                                : i18n.t("Upload")}
                            </Label>
                          </Button>
                          <Input
                            id="uploadFloorPlan"
                            name="file"
                            type="file"
                            accept="application/pdf,image/*"
                            style={{ display: "none" }}
                            onChange={e => {
                              setFloorPlanFile(e.target.files[0])
                            }}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        {i18n.t("Introduction Text")}
                      </CardTitle>
                      <p>{i18n.t("Intro Title")}</p>
                      <TranslationInputPair
                        english={introTitleEn}
                        spanish={introTitleEs}
                        setEnglish={setIntroTitleEn}
                        setSpanish={setIntroTitleEs}
                      />
                      <p style={{ marginTop: 20 }}>
                        {i18n.t("Intro Subtitle")}
                      </p>
                      <TranslationTextAreaPair
                        english={introSubtitleEn}
                        spanish={introSubtitleEs}
                        setEnglish={setIntroSubtitleEn}
                        setSpanish={setIntroSubtitleEs}
                      />
                      <p style={{ marginTop: 20 }}>{i18n.t("Intro Content")}</p>
                      <TranslationTextAreaPair
                        english={introContentEn}
                        spanish={introContentEs}
                        setEnglish={setIntroContentEn}
                        setSpanish={setIntroContentEs}
                      />
                      <p style={{ marginTop: 20 }}>{i18n.t("Meta Title")}</p>
                      <TranslationTextAreaPair
                        english={metaTitleEn}
                        spanish={metaTitleEs}
                        setEnglish={setMetaTitleEn}
                        setSpanish={setMetaTitleEs}
                      />
                      <p style={{ marginTop: 20 }}>
                        {i18n.t("Meta Description")}
                      </p>
                      <TranslationTextAreaPair
                        english={metaDescriptionEn}
                        spanish={metaDescriptionEs}
                        setEnglish={setMetaDescriptionEn}
                        setSpanish={setMetaDescriptionEs}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <CardTitle className="h5 mb-4">
                        {i18n.t("Images")}
                      </CardTitle>
                      {images.length > 0 && (
                        <ImageUploaderMultiple
                          images={images}
                          setImages={setImages}
                        />
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        {i18n.t("Descriptions")}
                      </CardTitle>

                      <p>{i18n.t("Description Title")}</p>

                      <TranslationInputPair
                        english={descriptionTitleEn}
                        spanish={descriptionTitleEs}
                        setEnglish={setDescriptionTitleEn}
                        setSpanish={setDescriptionTitleEs}
                      />

                      <p style={{ marginTop: 20 }}>
                        {i18n.t("Description Subtitle")}
                      </p>

                      <TranslationTextAreaPair
                        english={descriptionSubtitleEn}
                        spanish={descriptionSubtitleEs}
                        setEnglish={setDescriptionSubtitleEn}
                        setSpanish={setDescriptionSubtitleEs}
                      />

                      <p style={{ marginTop: 20 }}>
                        {i18n.t("Description Content")}
                      </p>

                      <TranslationTextAreaPair
                        english={descriptionContentEn}
                        spanish={descriptionContentEs}
                        setEnglish={setDescriptionContentEn}
                        setSpanish={setDescriptionContentEs}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        {i18n.t("Location Details")}
                      </CardTitle>

                      <p>{i18n.t("Location Title")}</p>

                      <TranslationInputPair
                        english={locationTitleEn}
                        spanish={locationTitleEs}
                        setEnglish={setLocationTitleEn}
                        setSpanish={setLocationTitleEs}
                      />

                      <p style={{ marginTop: 20 }}>
                        {i18n.t("Location Subtitle")}
                      </p>

                      <TranslationTextAreaPair
                        english={locationSubtitleEn}
                        spanish={locationSubtitleEs}
                        setEnglish={setLocationSubtitleEn}
                        setSpanish={setLocationSubtitleEs}
                      />

                      <p style={{ marginTop: 20 }}>
                        {i18n.t("Location Content")}
                      </p>

                      <TranslationTextAreaPair
                        english={locationContentEn}
                        spanish={locationContentEs}
                        setEnglish={setLocationContentEn}
                        setSpanish={setLocationContentEs}
                      />
                      <p style={{ marginTop: 20 }}>
                        {i18n.t("Location Description")}
                      </p>

                      <TranslationTextAreaPair
                        english={locationDescEn}
                        spanish={locationDescEs}
                        setEnglish={setLocationDescEn}
                        setSpanish={setLocationDescEs}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  {center && (
                    <GoogleMap
                      center={center}
                      height="700px"
                      zoom={15}
                      setMapAddress={setMapAddress}
                      setLat={setLat}
                      setLng={setLng}
                    />
                  )}
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

EditProperty.propTypes = {
  location: PropTypes.object,
}

export default withRouter(EditProperty)
