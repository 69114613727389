import axios from "axios"
import getToken from "./jwt-token-access/accessToken"


 
//apply base url for axios
const API_URL = ""

const axiosApi = axios.create({
  baseURL: API_URL,
})


axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config, headers: {
    'Authorization': 'Bearer ' + getToken()
  } }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  console.log(url);
  return axiosApi
    .post(url, { ...data }, { ...config, headers: {
      'Authorization': 'Bearer ' + getToken()
    } })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config, headers: {
      'Authorization': 'Bearer ' + getToken()
    } })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config, headers: {
      'Authorization': 'Bearer ' + getToken()
    } })
    .then(response => response.data)
}
