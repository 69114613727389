import React, { useEffect, useState } from "react"
import { MDBDataTable, MDBTableFoot } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, Badge, Button } from "reactstrap"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"

import "./datatables.scss"
import { toast } from "react-toastify"
import {
  useMutation,
  QueryCache,
  QueryClient,
  useQueryClient,
} from "react-query"
import axios from "axios"
import i18n from "../../i18n"
import AddModal from "./ShareModal"

const ActionButtonsProperty = ({ deactivate, toggleModal, item }) => {
  let history = useHistory()

  ActionButtonsProperty.propTypes = {
    item: PropTypes.object,
    deactivate: PropTypes.func,
    toggleModal: PropTypes.func,
  }
  let id = item.id
  let status = item.status

  return (
    <>
      <Button
        type="button"
        color="primary"
        size="sm"
        className="btn-rounded waves-effect waves-light action-button"
        style={{ marginbottom: 2, marginTop: 2 }}
        target={"blank"}
        href={`/property/preview/${id}`}
      >
        {i18n.t("Preview")}
      </Button>

      <Button
        type="button"
        color="warning"
        size="sm"
        className="btn-rounded waves-effect waves-light action-button"
        style={{ marginbottom: 2, marginTop: 2 }}
        href={`/property/edit/${id}`}
      >
        {i18n.t("Edit")}
      </Button>

      <Button
        type="button"
        color="warning"
        size="sm"
        className="btn-rounded waves-effect waves-light action-button"
        style={{ marginbottom: 2, marginTop: 2 }}
        href={`https://stories.brily.dev/${item.descriptions[0].name.toLowerCase()}-en.html`}
        target="_blank"
      >
        {i18n.t("Virw Story")}
      </Button>
      <Button
        type="button"
        color="danger"
        size="sm"
        className="btn-rounded waves-effect waves-light action-button"
        style={{ marginbottom: 2, marginTop: 2 }}
        onClick={e => deactivate(e, id, status)}
      >
        {status == 1 ? i18n.t("Deactivate") : status == 0 && i18n.t("Activate")}
      </Button>
      {status === 1 && (
        <Button
          type="button"
          color="success"
          size="sm"
          className="btn-rounded waves-effect waves-light action-button"
          style={{ marginbottom: 2, marginTop: 2 }}
          onClick={e => toggleModal(e, item)}
        >
          {i18n.t("Share")}
        </Button>
      )}
    </>
  )
}

const PropertiesTable = ({ properties, activeOnly }) => {
  // const dispatch = useDispatch();
  const [stateProperties, setStateProperties] = useState([])
  const [modal, setModal] = useState(false)
  const [item, setItem] = useState(false) // Store item to share

  var token = JSON.parse(localStorage.getItem("authUser")).token
  const mutation = useMutation(details => {
    return axios.post(
      `${process.env.REACT_APP_API_ROOT}/status_update`,
      details,
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    )
  })
  let queryclient = useQueryClient()
  const deactivate = (e, id, status) => {
    e.preventDefault()
    let stat = 0
    if (status == 1) {
      stat = 0
    } else {
      stat = 1
    }
    let details = { id: id, status: stat }
    mutation.mutate(details, {
      onSuccess: data => {
        toast.success(
          data.data.status === 0
            ? i18n.t("Deactivated")
            : data.data.status === 1 && i18n.t("Activated")
        )
        queryclient.invalidateQueries("myProperties")
      },
      onError: data => {
        console.log(data)
        toast.error(i18n.t("Internal server error"))
      },
    })
  }

  const toggleModal = (e, item) => {
    if (!e) {
      setItem(false)
      setModal(!modal)
    } else {
      e.preventDefault()
      setItem(item)
      setModal(!modal)
    }
  }

  useEffect(() => {
    let rowData = []
    if (properties.isSuccess) {
      properties.data.map(item => {
        if (
          (activeOnly && item.status == 1) ||
          (!activeOnly && item.status != 1)
        ) {
          rowData.push({
            status: (
              <Badge className={getBadgeVariant(item.status).color}>
                {getBadgeVariant(item.status).text}
              </Badge>
            ),
            name: item.descriptions[0].name,
            ref: item.reference,
            price: item.price.toLocaleString(),
            bedrooms: item.beds,
            bathrooms: item.baths,
            dateAdded: new Date(item.createdOn).toLocaleDateString(),
            actions: (
              <ActionButtonsProperty
                id={item.id}
                deactivate={deactivate}
                status={item.status}
                item={item}
                toggleModal={toggleModal}
              ></ActionButtonsProperty>
            ),
          })
        }
      })
      setStateProperties(rowData)
    }
  }, [properties])

  const data = {
    columns: [
      {
        label: i18n.t("Status"),
        field: "status",
        width: 150,
      },
      {
        label: i18n.t("Name"),
        field: "name",
        width: 270,
      },
      {
        label: i18n.t("Reference"),
        field: "ref",
        width: 270,
      },
      {
        label: i18n.t("Price (€)"),
        field: "price",
        width: 200,
      },
      {
        label: i18n.t("Beds"),
        field: "bedrooms",
        width: 50,
      },
      {
        label: i18n.t("Baths"),
        field: "bathrooms",
        width: 50,
      },
      {
        label: i18n.t("Date Added"),
        field: "dateAdded",
        sort: "desc",
        width: 100,
      },
      {
        label: i18n.t("Action"),
        field: "actions",
      },
    ],
    rows: stateProperties,
  }

  return (
    <React.Fragment>
      <div>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>
                    {activeOnly
                      ? i18n.t("Active Properties")
                      : i18n.t("Inactive Properties")}
                  </CardTitle>

                  <MDBDataTable responsive striped borderless data={data}>
                    <MDBTableFoot> </MDBTableFoot>
                  </MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <AddModal toggle={toggleModal} modal={modal} item={item} />
      </div>
    </React.Fragment>
  )
}

const getBadgeVariant = status => {
  switch (status) {
    case 1:
      return { color: "bg-success", text: i18n.t("ACTIVE") }
    case 2:
      return { color: "bg-error", text: i18n.t("SOLD") }
    case 0:
      return { color: "bg-info", text: i18n.t("COMING SOON") }
  }
}

PropertiesTable.propTypes = {
  properties: PropTypes.object,
  activeOnly: PropTypes.bool,
}

export default PropertiesTable
