import React, { useEffect, useState } from "react"
import {
  MDBDataTable,
  MDBTableHead,
  MDBTableBody,
  MDBTableFoot,
  MDBInput,
  MDBBtn,
} from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Badge,
  Button,
} from "reactstrap"
import PropTypes from "prop-types"
import { importProperty } from "../../helpers/apiRepository"
import i18n from "../../i18n"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

const ImportTable = ({ properties }) => {
  const [stateProperties, setStateProperties] = useState([])
  const [selectedProperties, setSelectedProperties] = useState([])

  const importProperties = () => {
    selectedProperties.forEach(propId => {
      importProperty(propId)
    })
  }

  const modifySelected = (checked, id) => {
    let oSel = selectedProperties
    if (checked) {
      oSel.push(id)
    } else {
      oSel = oSel.filter(function (item) {
        return item !== id
      })
    }
    setSelectedProperties(oSel)
    console.log(oSel)
  }

  useEffect(() => {
    let rowData = []

    if (properties.isSuccess) {
      if (properties.data.length > 0) {
        properties.data.map(item => {
          rowData.push({
            selected: (
              <MDBInput
                type="checkbox"
                id="checkbox2"
                label=" "
                onChange={e => modifySelected(e.target.checked, item.id)}
              />
            ),
            source: item.source,
            image: <img src={item.image} width="150" />,
            details: item.details,
            sourceDate: new Date(item.sourceDate).toLocaleDateString(),
          })
        })
        setStateProperties(rowData)
      }
    }
  }, [properties])

  const data = {
    columns: [
      {
        label: "",
        field: "selected",
        width: 10,
      },
      {
        label: i18n.t("Source"),
        field: "source",
        width: 150,
      },
      {
        label: i18n.t("Image"),
        field: "image",
        width: 150,
      },
      {
        label: i18n.t("Details"),
        field: "details",
        width: 270,
      },
      {
        label: i18n.t("Source Date"),
        field: "sourceDate",
        sort: "desc",
        width: 100,
      },
    ],
    rows: stateProperties,
  }

  return (
    <React.Fragment>
      <div>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle style={{ marginBottom: 20 }}>
                    <Row>
                      <Col className="col-11">{i18n.t("Imported Properties List")}</Col>
                      <Col className="col-1">
                        <Button
                          type="button"
                          color="primary"
                          size="sm"
                          className="btn-rounded waves-effect waves-light action-button"
                          style={{ marginbottom: 2, marginTop: 2,padding: "5px 20px" }}
                          onClick={importProperties}
                        >
                          {i18n.t("Import")}
                        </Button>
                      </Col>
                    </Row>
                  </CardTitle>

                  <MDBDataTable
                    responsive
                    striped
                    borderless
                    data={data}
                    searching={true}
                    style={{ marginTop: 20 }}
                  >
                    <MDBTableFoot> </MDBTableFoot>
                  </MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

ImportTable.propTypes = {
  properties: PropTypes.any,
}

export default ImportTable
