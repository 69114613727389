import _ from "lodash"

export let types = {
  propertyName: "Property Name",
  price: "Property Price",
  yearBuilt: "Property Year",
  beds: "Property Beds",
  baths: "Property Baths",
  areaM: "Property Area",
  plot: "property Plot",
  // videoPath: "Video Url",
  // videoProvider: "Video Provider",
  mapAddress: "Map Address",
  floorPlan: "Floor Plan File",
  longitude: "Longitude",
  latitude: "latitude",
  noIndex: "No Index",
  // Description En Starts
  langEn: "en",
  locationDescriptionEn: "Location Description in Eng",
  titleEn: "Title in Eng",
  introTitleEn: "Intro Title in Eng",
  introSubtitleEn: "Intro Subtitle in Eng",
  introContentEn: "Intro Content in Eng",
  descriptionTitleEn: "Description Title in Eng",
  descriptionSubtitleEn: "Description Subtitle in Eng",
  descriptionContentEn: "Description Content in Eng",
  locationTitleEn: "Location Title in Eng",
  locationSubtitleEn: "Location Subtitle in Eng",
  locationContentEn: "Location Content in Eng",
  metaTitleEn: "Meta Title in Eng",
  metaDescriptionEn: "Meta Description in Eng",
  // Description Es Starts
  langEs: "en",
  locationDescriptionEs: "Location Description in ES",
  titleEs: "Title in ES",
  introTitleEs: "Intro Title in ES",
  introSubtitleEs: "Intro Subtitle in ES",
  introContentEs: "Intro Content in ES",
  descriptionTitleEs: "Description Title in ES",
  descriptionSubtitleEs: "Description Subtitle in ES",
  descriptionContentEs: "Description Content in ES",
  locationTitleEs: "Location Title in ES",
  locationSubtitleEs: "Location Subtitle in ES",
  locationContentEs: "Location Content in ES",
  metaTitleEs: "Meta Title in ES",
  metaDescriptionEs: "Meta Description in ES",
  reference: "Reference Value",
  storyTemplate: "Story Template",
  propertyTemplate: "Property Template",
  images: "Images",
}
let validator = body => {
  for (const val in body) {
    let item = body[val]
    if (
      typeof item !== "number" &&
      val == "floorPlan" &&
      typeof item !== "object"
    ) {
      if (_.isEmpty(item) || item == "") {
        return `${types[val]} Should Not Be Empty.`
      }
    }
  }
}

export let validateEmail = email => {
  var re = /\S+@\S+\.\S+/
  return re.test(email)
}

export let validateURL = url => {
  var re = /^(?:http(s)?:)/gm
  return re.test(url)
}

export default validator
