import React, { useEffect, useState } from "react"
import {
  MDBDataTable,
  MDBTableHead,
  MDBTableBody,
  MDBTableFoot,
} from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, Badge, Button } from "reactstrap"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { useMutation, useQueryClient } from "react-query"
import AddModal from "./Modal"
import axios from "axios"
import { toast } from "react-toastify"
import i18n from '../../i18n'

// import "./datatables.scss"

const ActionButtonsProperty = ({ id, removeDomain }) => {
  let history = useHistory()

  ActionButtonsProperty.propTypes = {
    id: PropTypes.number,
    removeDomain: PropTypes.func,
  }

  return (
    <>
      <Button
        type="button"
        color="danger"
        size="sm"
        className="btn-rounded waves-effect waves-light action-button"
        style={{ marginbottom: 2, marginTop: 2 }}
        onClick={() => {
          removeDomain({ id })
        }}
      >
        {i18n.t("Remove")}
      </Button>
    </>
  )
}

const DomainTable = ({ domains, activeOnly }) => {
  const [domainsData, setDomainsData] = useState([])
  const [modal, setModal] = useState(false)

  const queryClient = useQueryClient(); 

  var token = JSON.parse(localStorage.getItem("authUser")).token
  const mutation = useMutation(body => {
    return axios.delete(`${process.env.REACT_APP_API_ROOT}/domains`, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: body
    })
  })

  const removeDomain = body => {
    mutation.mutate(body, {
      onSuccess: data => {
        queryClient.invalidateQueries('myDomains')
      }})

    
    mutation.isSuccess &&
      toast.success(i18n.t("Domain Removed"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    mutation.isError &&
      toast.error(i18n.t("Check Details and try again!"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
  }
  useEffect(() => {
    let rowData = []
    if (domains.status === "success") {
      domains.data.map(item => {
        if (item.active) {
          rowData.push({
            domain_name: item.domain,
            property_name: item.property.descriptions[0].name,
            property_id: item.propertyId,
            dateAdded: new Date(item.createdOn).toLocaleDateString(),
            actions: (
              <ActionButtonsProperty
                id={item.id}
                removeDomain={removeDomain}
              ></ActionButtonsProperty>
            ),
          })
        }
      })
    }
    setDomainsData(rowData)
  }, [domains])

  const data = {
    columns: [
      {
        label: i18n.t("Domain Name"),
        field: "domain_name",
        width: 150,
      },
      {
        label: i18n.t("Property ID"),
        field: "property_id",
        width: 270,
      },
      {
        label: i18n.t("Property Name"),
        field: "property_name",
        width: 200,
      },
      {
        label: i18n.t("Action"),
        field: "actions",
      },
    ],
    rows: domainsData,
  }

  const toggleModal = () => {
    setModal(!modal)
  }

  return (
    <React.Fragment>
      <div>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>
                    <Row>
                      <Col className="col-10">{i18n.t("Active Properties")}</Col>
                      <Col className="col-2">
                        <Button
                          type="button"
                          color="primary"
                          size="sm"
                          className="btn-rounded waves-effect waves-light action-button float-end"
                          style={{ marginbottom: 2, marginTop: 2 }}
                          onClick={toggleModal}
                        >
                          {i18n.t("Add Domain")}
                        </Button>
                      </Col>
                    </Row>
                  </CardTitle>

                  <MDBDataTable responsive striped borderless data={data}>
                    <MDBTableFoot> </MDBTableFoot>
                  </MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <AddModal toggle={toggleModal} modal={modal} />
    </React.Fragment>
  )
}

DomainTable.propTypes = {
  domains: PropTypes.object,
  activeOnly: PropTypes.bool,
}

export default DomainTable
