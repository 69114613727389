import React, { useEffect, useState } from "react"
import {
  MDBDataTable,
  MDBTableHead,
  MDBTableBody,
  MDBTableFoot,
} from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { useMutation, useQueryClient } from "react-query"
import AddModal from "./Modal"
import axios from "axios"
import { toast } from "react-toastify"
import i18n from "../../i18n"
// import "./datatables.scss"

const ActionButtonsProperty = ({ item, editLead, toggleModal }) => {
  let history = useHistory()

  ActionButtonsProperty.propTypes = {
    item: PropTypes.object,
    editLead: PropTypes.func,
    toggleModal: PropTypes.func,
  }

  return (
    <>
      <Button
        type="button"
        color="primary"
        size="sm"
        className="btn-rounded waves-effect waves-light action-button"
        style={{ marginbottom: 2, marginTop: 2 }}
        onClick={() => {
          history.push("/leads/view/" + item.id)
        }}
      >
        {i18n.t("View")}
      </Button>
      <Button
        type="button"
        color="warning"
        size="sm"
        className="btn-rounded waves-effect waves-light action-button"
        style={{ marginbottom: 2, marginTop: 2 }}
        onClick={() => {
          toggleModal(item, true)
        }}
      >
        {i18n.t("Edit")}
      </Button>
    </>
  )
}

const ViewProperty = ({ item }) => {
  ViewProperty.propTypes = {
    item: PropTypes.object,
  }
  let id = item.id
  return (
    <a
      type="button"
      color="primary"
      size="sm"
      className="btn-rounded waves-effect waves-light action-button"
      style={{ marginbottom: 2, marginTop: 2 }}
      target={"blank"}
      href={`/property/preview/${id}`}
    >
      {item.property.reference}
    </a>
  )
}
const StatusHandler = ({ item, updateLeadStatus }) => {
  let history = useHistory()
  const statusDetails = ["New", "Active", "Contacted", "Hidden"]
  const [status, setStatus] = useState(false)
  StatusHandler.propTypes = {
    item: PropTypes.object,
    updateLeadStatus: PropTypes.func,
    toggleModal: PropTypes.func,
  }

  let update = () => {
    let details = {
      name: item.leadName,
      telephone: item.leadTelephone,
      email: item.leadEmail,
      message: item.leadMessage,
      propertyId: item.propertyId,
      agentId: item.agentId,
      id: item.id,
      status,
      ip: "No IP ( changed by admin )",
    }

    details.notes = `Lead status changed from ${item.status} to ${status}`

    updateLeadStatus(details)
  }

  useEffect(() => {
    if (status) {
      update()
    }
  }, [status])

  return (
    <>
      <UncontrolledDropdown className="input_group">
        <DropdownToggle caret className="property_dropdown">
          {status ? status : item.status}
        </DropdownToggle>
        <DropdownMenu className="property_dropdown">
          {statusDetails &&
            statusDetails.map((item, key) => {
              return (
                <DropdownItem
                  key={key}
                  onClick={() => {
                    setStatus(item)
                  }}
                >
                  {item}
                </DropdownItem>
              )
            })}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
}

const shortenString = string => {
  var yourString = string
  var maxLength = 20 // first 30 characters

  //trim the string to the maximum length
  var trimmedString = yourString.substr(0, maxLength)

  //re-trim if we are in the middle of a word
  trimmedString = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
  )
  return trimmedString
}

const DomainTable = ({ leads, activeOnly }) => {
  var user = JSON.parse(localStorage.getItem("authUser"))
  const [leadsData, setLeadsData] = useState([])
  const [modal, setModal] = useState(false)
  const [itemInModal, setItemInModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const queryClient = useQueryClient()

  var token = JSON.parse(localStorage.getItem("authUser")).token
  const mutation = useMutation(body => {
    return axios.delete(`${process.env.REACT_APP_API_ROOT}/leads`, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: body,
    })
  })
  const status_mutation = useMutation(details => {
    return axios.post(`${process.env.REACT_APP_API_ROOT}/contact`, details, {
      headers: {
        "Content-Type": "application/json",
      },
    })
  })

  const editLead = body => {
    mutation.mutate(body, {
      onSuccess: data => {
        queryClient.invalidateQueries("myDomains")
      },
    })

    mutation.isSuccess && toast.success(i18n.t("Domain Removed"))
    mutation.isError && toast.error(i18n.t("Check Details and try again!"))
  }
  useEffect(() => {
    let rowData = []
    if (leads.data) {
      leads.data.map(item => {
        if (user.isAgentAdmin || user.isAdmin) {
          rowData.push({
            id: item.id,
            agent_id: item?.agentId,
            property_id: item.property?.id,
            lead_name: item?.leadName,
            lead_telephone: item?.leadTelephone,
            lead_email: item?.leadEmail,
            lead_message: item?.leadMessage,
            status: (
              <StatusHandler
                item={item}
                updateLeadStatus={updateLeadStatus}
              ></StatusHandler>
            ),
            type: item?.type,
            property_reference: <ViewProperty item={item}></ViewProperty>,
            notes: item?.leadNotes.length > 0 ? item.leadNotes.length : 0,
            updated_at:
              item?.leadNotes.length > 0 &&
              item?.leadNotes[item.leadNotes.length - 1].modifiedAt.slice(
                0,
                10
              ) +
                " " +
                new Date(
                  item?.leadNotes[item.leadNotes.length - 1].modifiedAt
                ).toLocaleTimeString("en-US", {
                  timeZone: "UTC",
                  hour12: true,
                  hour: "numeric",
                  minute: "numeric",
                }),
            actions: (
              <ActionButtonsProperty
                item={item}
                editLead={editLead}
                toggleModal={toggleModal}
              ></ActionButtonsProperty>
            ),
          })
        }
        if (!user.isAgentAdmin && !user.isAdmin) {
          if (user.id == item.agentId) {
            rowData.push({
              id: item.id,
              agent_id: item?.agentId,
              property_id: item?.property.id,
              lead_name: item?.leadName,
              lead_telephone: item?.leadTelephone,
              lead_email: item?.leadEmail,
              lead_message: shortenString(item?.leadMessage),
              status: item?.status,
              type: item?.type,
              notes:
                item.leadNotes.length > 0 &&
                item?.leadNotes[item.leadNotes.length - 1].notes,
              updated_at:
                item.leadNotes.length > 0 &&
                item?.leadNotes[item.leadNotes.length - 1].modifiedAt,
              actions: (
                <ActionButtonsProperty
                  item={item}
                  editLead={editLead}
                  toggleModal={toggleModal}
                ></ActionButtonsProperty>
              ),
            })
          }
        }
      })
    }
    setLeadsData(rowData)
  }, [leads])

  const data = {
    columns: [
      {
        label: i18n.t("ID"),
        field: "id",
      },
      {
        label: i18n.t("Agent ID"),
        field: "agent_id",
      },
      {
        label: i18n.t("Property ID"),
        field: "property_id",
      },
      {
        label: i18n.t("Name"),
        field: "lead_name",
      },
      {
        label: i18n.t("Telephone"),
        field: "lead_telephone",
      },
      {
        label: i18n.t("Email"),
        field: "lead_email",
      },
      {
        label: i18n.t("Status"),
        field: "status",
      },
      {
        label: i18n.t("Notes"),
        field: "notes",
      },
      {
        label: i18n.t("Type"),
        field: "type",
      },
      {
        label: "Property Reference",
        field: "property_reference",
      },
      {
        label: i18n.t("Updated At"),
        field: "updated_at",
      },

      {
        label: i18n.t("Action"),
        field: "actions",
      },
    ],
    rows: leadsData,
  }

  const toggleModal = (item, isEditing) => {
    setModal(!modal)
    setItemInModal(item)
    setIsEdit(isEditing ? isEditing : false)
  }

  const updateLeadStatus = details => {
    console.log(details)
    status_mutation.mutate(details, {
      onSuccess: data => {
        toast.success("Updated!")
      },
      onError: data => {
        toast.error("Check Details and try again!")
      },
    })
  }

  return (
    <React.Fragment>
      <div>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>
                    <Row>
                      <Col className="col-10">{i18n.t("Active Leads")}</Col>
                    </Row>
                  </CardTitle>

                  <MDBDataTable
                    responsive
                    striped
                    borderless
                    data={data}
                    autoWidth={false}
                  >
                    <MDBTableFoot> </MDBTableFoot>
                  </MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <AddModal
        toggle={toggleModal}
        modal={modal}
        itemInModal={itemInModal}
        isEdit={isEdit}
      />
    </React.Fragment>
  )
}

DomainTable.propTypes = {
  leads: PropTypes.object,
  activeOnly: PropTypes.bool,
}

export default DomainTable
