import React from "react"
import { Container, Row, Col } from "reactstrap"
import { getImportedProperties } from "../../helpers/apiRepository"
import ImportTable from "./ImportTable"
import { useQuery } from "react-query"
import "./ImportPage.css"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import i18n from "../../i18n"
import { withTranslation } from "react-i18next"

const Properties = () => {
  const properties = useQuery("importData", getImportedProperties)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={i18n.t("Properties")}
            breadcrumbItem={i18n.t("Import Properties")}
          />
          <Row>
            <Col xl="12">
              <ImportTable properties={properties} activeOnly={false} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Properties)
