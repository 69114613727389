import React, { useEffect, useState } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  Input,
} from "reactstrap"
import PropTypes from "prop-types"
import { FacebookShareButton, TwitterShareButton } from "react-share"
import { FacebookIcon, TwitterIcon } from "react-share"
import i18n from "../../i18n"

const numberWithCommas = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
const AddModal = ({ modal, toggle, item }) => {
  var user = JSON.parse(localStorage.getItem("authUser"))
  const [sharabeUrl, setSharableURL] = useState("Domain is not set yet")
  const [desc, setDesc] = useState(false)

  // ${
  //   item.descriptions[0].name &&
  //   `${i18n.t("Name")}: ${removeHTML(item.descriptions[0].name)}`
  // } \n
  // ${
  //   item.descriptions[0].introTitle &&
  //   `${i18n.t("Title")}: ${removeHTML(item.descriptions[0].introTitle)}`
  // } \n
  // ${
  //   item.descriptions[0].locationDescription &&
  //   `${i18n.t("Description")}: ${removeHTML(
  //     item.descriptions[0].locationDescription
  //   )}`
  // } \n
  useEffect(() => {
    console.log(item);
    if (item) {
      setDesc(
        `${i18n.t("Stats")}: ${
          item.areaM && `${i18n.t("Area")}: ${item.areaM}`
        } m2, ${item.beds && `${i18n.t("Beds")}: ${item.beds}`}, ${
          item.baths && `${i18n.t("Baths")}: ${item.baths}`
        } \n
        ${i18n.t("Price")}: ${numberWithCommas(item.price)}`
      )
      if (user.isAgentAdmin) {
        if (item.Domain.length > 0) {
          setSharableURL(`${item.Domain[0].domain}`)
        } else {
          setSharableURL(`${window.location.origin}/property/preview/${item.id}`)
        }
      } else {
        if (item.Domain.length > 0) {
          setSharableURL(`${item.Domain[0].domain}/?sourceId=${user.id}`)
        } else {
          setSharableURL(`${window.location.origin}/property/preview/${item.id}`)
        }
      }
    }
  }, [item])

  return (
    <div>
      <Modal isOpen={modal}>
        <ModalHeader toggle={toggle}>{i18n.t("Share Property")}</ModalHeader>
        <ModalBody>
          <InputGroup className="input_group">
            <Input placeholder="Domain Name" value={sharabeUrl} readOnly />
            <InputGroupAddon addonType="prepend">
              <Button
                color="secondary"
                onClick={() => {
                  navigator.clipboard.writeText(sharabeUrl)
                }}
              >
                {i18n.t("Copy Url")}
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <div className="text-center">
            <FacebookShareButton
              url={sharabeUrl}
              quote={desc}
              className="Demo__some-network__share-button"
              title="Sharing for testing"
            >
              <FacebookIcon size={32} round /> {i18n.t("Facebook ")}
            </FacebookShareButton>{" "}
            <TwitterShareButton
              url={sharabeUrl}
              quote={desc}
              className="share_btn"
              title="Sharing for testing"
            >
              <TwitterIcon size={32} round /> {i18n.t("Twitter")}
            </TwitterShareButton>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              toggle()
            }}
          >
            {i18n.t("Add")}
          </Button>{" "}
          <Button color="secondary" onClick={() => toggle(false)}>
            {i18n.t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

AddModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  item: PropTypes.any,
}
export default AddModal
