import React, { useEffect, useState } from "react"
import "./createproperty.css"
import MetaTags from "react-meta-tags"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Form,
  Input,
  Button,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap"
import TranslationInputPair from "../../components/Common/TranslationInputPair"
import TranslationTextAreaPair from "../../components/Common/TranslationTextAreaPair"
import i18n from "../../i18n"
import ImageUploaderMultiple from "../../components/Common/ImageUploaderMultiple/ImageUploaderMultiple"
import PropertyFeatures from "../../components/Common/PropertyFeatures/PropertyFeatures"
import { FaUpload } from "react-icons/fa"
import { useMutation } from "react-query"
import axios from "axios"
import { toast } from "react-toastify"

import GoogleMap from "../../components/GoogleMap/map"
import { useHistory } from "react-router-dom"

function CreateProperty() {
  let history = useHistory()
  const [propertyName, setPropertyName] = useState("")
  const [propertyActive, setPropertyActive] = useState(false)
  const [propertyHide, setPropertyHide] = useState(false)
  const [propertyPrice, setPropertyPrice] = useState(0)
  const [propertyYear, setPropertyYear] = useState("")
  const [propertyBeds, setPropertyBeds] = useState("")
  const [propertyBaths, setPropertyBaths] = useState("")
  const [propertyArea, setPropertyArea] = useState(0)
  const [propertyPlot, setPropertyPlot] = useState(0)

  const [videoUrl, setVideoUrl] = useState("")
  const [videoProvider, setVideoProvider] = useState(i18n.t("Select Provider"))
  const [isVideoDropdownOpen, setIsVideoDropdownOpen] = useState(false)

  const [introTitleEn, setIntroTitleEn] = useState("")
  const [introTitleEs, setIntroTitleEs] = useState("")

  const [introContentEn, setIntroContentEn] = useState("")
  const [introContentEs, setIntroContentEs] = useState("")

  const [introSubtitleEn, setIntroSubtitleEn] = useState("")
  const [introSubtitleEs, setIntroSubtitleEs] = useState("")

  const [descriptionTitleEn, setDescriptionTitleEn] = useState("")
  const [descriptionTitleEs, setDescriptionTitleEs] = useState("")

  const [descriptionContentEn, setDescriptionContentEn] = useState("")
  const [descriptionContentEs, setDescriptionContentEs] = useState("")

  const [descriptionSubtitleEn, setDescriptionSubtitleEn] = useState("")
  const [descriptionSubtitleEs, setDescriptionSubtitleEs] = useState("")

  const [locationTitleEn, setLocationTitleEn] = useState("")
  const [locationTitleEs, setLocationTitleEs] = useState("")

  const [locationContentEn, setLocationContentEn] = useState("")
  const [locationContentEs, setLocationContentEs] = useState("")

  const [mapAddress, setMapAddress] = useState("")
  const [lat, setLat] = useState(40.416775)
  const [lng, setLng] = useState(-3.70379)

  const [locationDescEn, setLocationDescEn] = useState("")
  const [locationDescEs, setLocationDescEs] = useState("")

  const [metaTitleEn, setMetaTitleEn] = useState("")
  const [metaTitleEs, setMetaTitleEs] = useState("")

  const [metaDescriptionEn, setMetaDescriptionEn] = useState("")
  const [metaDescriptionEs, setMetaDescriptionEs] = useState("")

  const [locationSubtitleEn, setLocationSubtitleEn] = useState("")
  const [locationSubtitleEs, setLocationSubtitleEs] = useState("")

  const [featuresGB, setFeaturesGB] = useState([])
  const [featuresId, setFeaturesId] = useState([])

  const [floorPlanFile, setFloorPlanFile] = useState("")

  const [images, setImages] = useState([])
  useEffect(() => {
    let result = []
    featuresGB.forEach(item => {
      result.push({ featureId: item.feature.id })
    })
    setFeaturesId(result)
  }, [featuresGB])

  var token = JSON.parse(localStorage.getItem("authUser")).token
  const mutation = useMutation(body => {
    return axios.post(
      `${process.env.REACT_APP_API_ROOT}/properties?lang=${i18n.language}`,
      body,
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      }
    )
  })

  const submit = e => {
    e.preventDefault()
    let details = {
      propertyName,
      status: 0,
      price: propertyPrice,
      yearBuilt: propertyYear,
      beds: propertyBeds,
      baths: propertyBaths,
      areaM: propertyArea,
      plot: propertyPlot,
      videoPath: videoUrl,
      videoProvider: videoProvider,
      floorPlan: floorPlanFile,
      PropertyFeatures: featuresId,
      // Description En Starts
      langEn: "en",
      nameEn: propertyName,
      locationDescriptionEn: locationDescEn,
      introTitleEn: introTitleEn,
      introSubtitleEn: introSubtitleEn,
      introContentEn: introContentEn,
      descriptionTitleEn: descriptionTitleEn,
      descriptionSubtitleEn: descriptionSubtitleEn,
      descriptionContentEn: descriptionContentEn,
      locationTitleEn: locationTitleEn,
      locationSubtitleEn: locationSubtitleEn,
      locationContentEn: locationContentEn,
      metaTitleEn: metaTitleEn,
      metaDescriptionEn: metaDescriptionEn,
      // Description Es Starts
      langEs: "en",
      nameEs: propertyName,
      locationDescriptionEs: locationDescEn,
      introTitleEs: introTitleEs,
      introSubtitleEs: introSubtitleEs,
      introContentEs: introContentEs,
      descriptionTitleEs: descriptionTitleEs,
      descriptionSubtitleEs: descriptionSubtitleEs,
      descriptionContentEs: descriptionContentEs,
      locationTitleEs: locationTitleEs,
      locationSubtitleEs: locationSubtitleEs,
      locationContentEs: locationContentEs,
      metaTitleEs: metaTitleEs,
      metaDescriptionEs: metaDescriptionEs,
      reference: referenceVal,
      storyTemplate: selectedStoryTemplate,
      propertyTemplate: selectedPropertyTemplate,
      images,
      mapAddress: mapAddress,
      longitude: lng,
      latitude: lat,
      noIndex: false,
    }
    let formData = new FormData()
    let isErr = false

    let errors = [undefined, null, "", " ", "Select Status", 0]
    if (errors.includes(details.price)) {
      toast.error(i18n.t(`Price is required`))
      isErr = true
    } else if (errors.includes(details.beds)) {
      toast.error(i18n.t(`Beds is required`))
      isErr = true
    } else if (errors.includes(details.introTitleEn)) {
      toast.error(i18n.t(`Title is required`))
      isErr = true
    } else if (errors.includes(details.reference)) {
      toast.error(i18n.t(`Reference is required`))
      isErr = true
    }

    for (var item in details) {
      if (item == "images") {
        details[item].forEach((val, key) => {
          formData.append(`${key}[]`, val)
        })
      } else if (item == "PropertyFeatures") {
        formData.append(item, JSON.stringify(details[item]))
      } else {
        formData.append(item, details[item])
      }
    }
    if (!isErr) {
      mutation.mutate(formData, {
        onSuccess: data => {
          toast.success(i18n.t("Added Successfully!"))
          setTimeout(() => {
            history.goBack()
          }, 2000)
        },
        onError: data => {
          toast.error(
            i18n.t(
              "Reference Field should be unique OR All fields are required"
            )
          )
        },
      })
    }
  }

  const [selectedStoryTemplate, setSelectedStoryTemplate] = useState(
    i18n.t("Select Story Template")
  )
  const [selectedPropertyTemplate, setSelectedPropertyTemplate] = useState(
    i18n.t("Select Property Template")
  )
  const [storyTemp, setStoryTemp] = useState(false)
  const [propertyTemp, setPropertyTemp] = useState(false)
  const [referenceVal, setReferenceVal] = useState("")
  const storyTemplates = ["Template 1"]

  const PropertyTemplates = ["Template 1"]

  const [center, setCenter] = useState({
    lat: 40.416775,
    lng: -3.70379,
  })
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{i18n.t("Create New Property | Brily")} ;)</title>
        </MetaTags>
        <Container fluid>
          <>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody style={{ display: "flex" }}>
                    <h3 style={{ marginTop: 5, marginBottom: 5 }}>
                      {i18n.t("Create New Property")}
                    </h3>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card style={{ display: "flex" }}>
                  <CardBody style={{ alignItems: "end", display: "flex" }}>
                    <Button
                      color="danger"
                      style={{ flex: 1, marginLeft: 5, marginRight: 5 }}
                    >
                      {i18n.t("Cancel")}
                    </Button>
                    <Button
                      color="success"
                      style={{ flex: 1, marginLeft: 5, marginRight: 5 }}
                      onClick={submit}
                    >
                      {i18n.t("Save Property")}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {i18n.t("Property Specification")}
                    </CardTitle>

                    <Form>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          {i18n.t("Name")}
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          value={propertyName}
                          onChange={e => setPropertyName(e.target.value)}
                        />
                      </div>

                      <Row>
                        <Col md={6}>
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="formrow-customCheck"
                              checked={propertyActive}
                              onChange={e => setPropertyActive(e.target.value)}
                            />
                            <Label
                              className="form-check-Label"
                              htmlFor="formrow-customCheck"
                            >
                              {i18n.t("Active")}
                            </Label>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="formrow-customCheck"
                              checked={propertyHide}
                              onChange={e => setPropertyHide(e.target.value)}
                            />
                            <Label
                              className="form-check-Label"
                              htmlFor="formrow-customCheck"
                            >
                              {i18n.t("Hide From Index")}
                            </Label>
                          </div>
                        </Col>
                      </Row>

                      <div className="mb-3"></div>

                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">
                              {i18n.t("Price")}
                            </Label>
                            <Input
                              type="number"
                              className="form-control"
                              id="formrow-email-Input"
                              value={propertyPrice}
                              onChange={e => setPropertyPrice(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-password-Input">
                              {i18n.t("Year Built")}
                            </Label>
                            <Input
                              type="number"
                              className="form-control"
                              id="formrow-password-Input"
                              value={propertyYear}
                              onChange={e => setPropertyYear(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={2}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">
                              {i18n.t("Beds")}
                            </Label>
                            <Input
                              type="number"
                              className="form-control"
                              id="formrow-InputZip"
                              value={propertyBeds}
                              onChange={e => setPropertyBeds(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">
                              {i18n.t("Baths")}
                            </Label>
                            <Input
                              type="number"
                              className="form-control"
                              id="formrow-InputZip"
                              value={propertyBaths}
                              onChange={e => setPropertyBaths(e.target.value)}
                            />
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputCity">
                              {i18n.t("Total Area (m2)")}
                            </Label>
                            <Input
                              type="number"
                              className="form-control"
                              id="formrow-InputCity"
                              value={propertyArea}
                              onChange={e => setPropertyArea(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputState">
                              {i18n.t("Plot Size (m2)")}
                            </Label>
                            <Input
                              type="number"
                              className="form-control"
                              id="formrow-InputCity"
                              value={propertyPlot}
                              onChange={e => setPropertyPlot(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-password-Input">
                              {i18n.t("Video Provider")}
                            </Label>
                            <br />
                            <ButtonDropdown
                              isOpen={isVideoDropdownOpen}
                              toggle={() =>
                                setIsVideoDropdownOpen(!isVideoDropdownOpen)
                              }
                              onClick={e => e.preventDefault()}
                            >
                              <Button id="caret" color="secondary">
                                {videoProvider}
                              </Button>
                              <DropdownToggle caret color="secondary">
                                <i className="mdi mdi-chevron-down" />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={e => setVideoProvider("vimeo")}
                                >
                                  {i18n.t("vimeo")}
                                </DropdownItem>
                              </DropdownMenu>
                            </ButtonDropdown>
                          </div>
                        </Col>
                        <Col md={9}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-password-Input">
                              {i18n.t("Video URL")}
                            </Label>
                            <Input
                              type="url"
                              className="form-control"
                              id="formrow-password-Input"
                              value={videoUrl}
                              onChange={e => {
                                setVideoUrl(e.target.value)
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody style={{ height: "100%" }}>
                    <CardTitle className="mb-4">{i18n.t("Features")}</CardTitle>
                    <PropertyFeatures
                      features={featuresGB}
                      updateFeatures={setFeaturesGB}
                      fromEdit={false}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">{i18n.t("Template")}</CardTitle>

                    <Form>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">
                              {i18n.t("Story Template")}
                            </Label>
                            <div>
                              <ButtonDropdown
                                style={{ width: "100%" }}
                                isOpen={storyTemp}
                                toggle={() => setStoryTemp(!storyTemp)}
                                onClick={e => e.preventDefault()}
                              >
                                <Button id="caret" color="secondary">
                                  {selectedStoryTemplate}
                                </Button>
                                <DropdownToggle caret color="secondary">
                                  <i className="mdi mdi-chevron-down" />
                                </DropdownToggle>
                                <DropdownMenu style={{ width: "100%" }}>
                                  <DropdownItem>
                                    {i18n.t("Select Story Template")}
                                  </DropdownItem>
                                  {storyTemplates.map((item, key) => (
                                    <DropdownItem
                                      key={key}
                                      onClick={() => {
                                        setSelectedStoryTemplate(item)
                                      }}
                                    >
                                      {item}
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </ButtonDropdown>
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">
                              {i18n.t("Property Template")}
                            </Label>
                            <div>
                              <ButtonDropdown
                                style={{ width: "100%" }}
                                isOpen={propertyTemp}
                                toggle={() => setPropertyTemp(!propertyTemp)}
                                onClick={e => e.preventDefault()}
                              >
                                <Button id="caret" color="secondary">
                                  {selectedPropertyTemplate}
                                </Button>
                                <DropdownToggle caret color="secondary">
                                  <i className="mdi mdi-chevron-down" />
                                </DropdownToggle>
                                <DropdownMenu style={{ width: "100%" }}>
                                  <DropdownItem>
                                    {i18n.t("Select Property Template")}
                                  </DropdownItem>
                                  {PropertyTemplates.map((item, key) => (
                                    <DropdownItem
                                      key={key}
                                      onClick={() => {
                                        setSelectedPropertyTemplate(item)
                                      }}
                                    >
                                      {item}
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </ButtonDropdown>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">
                              {i18n.t("Reference")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputZip"
                              value={referenceVal}
                              placeholder={i18n.t("20 character limits")}
                              onChange={e => {
                                setReferenceVal(e.target.value)
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {i18n.t("Floor Plan")}
                    </CardTitle>

                    <Row>
                      <Col
                        sm={4}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: 16,
                        }}
                      >
                        {i18n.t("PDF File Only")}
                      </Col>
                      <Col sm={6}>
                        <Button
                          style={{
                            flex: 1,
                            marginLeft: 5,
                            marginRight: 5,
                            fontSize: 18,
                            padding: 0,
                          }}
                          color="info"
                        >
                          <Label
                            for="uploadFloorPlan"
                            style={{
                              marginBottom: 0,
                              display: "flex",
                              alignItems: "center",
                              padding: 8,
                              cursor: "pointer",
                            }}
                          >
                            <FaUpload size={20} style={{ marginRight: 10 }} />{" "}
                            {floorPlanFile
                              ? i18n.t("Uploaded Successfully")
                              : i18n.t("Upload")}
                          </Label>
                        </Button>
                        <Input
                          id="uploadFloorPlan"
                          name="file"
                          type="file"
                          accept="application/pdf,image/*"
                          style={{ display: "none" }}
                          onChange={e => {
                            setFloorPlanFile(e.target.files[0])
                          }}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {i18n.t("Introduction Text")}
                    </CardTitle>
                    <p>{i18n.t("Intro Title")}</p>
                    <TranslationInputPair
                      english={introTitleEn}
                      spanish={introTitleEs}
                      setEnglish={setIntroTitleEn}
                      setSpanish={setIntroTitleEs}
                    />
                    <p style={{ marginTop: 20 }}>{i18n.t("Intro Subtitle")}</p>
                    <TranslationTextAreaPair
                      english={introSubtitleEn}
                      spanish={introSubtitleEs}
                      setEnglish={setIntroSubtitleEn}
                      setSpanish={setIntroSubtitleEs}
                    />
                    <p style={{ marginTop: 20 }}>{i18n.t("Intro Content")}</p>
                    <TranslationTextAreaPair
                      english={introContentEn}
                      spanish={introContentEs}
                      setEnglish={setIntroContentEn}
                      setSpanish={setIntroContentEs}
                    />
                    <p style={{ marginTop: 20 }}>{i18n.t("Meta Title")}</p>
                    <TranslationTextAreaPair
                      english={metaTitleEn}
                      spanish={metaTitleEs}
                      setEnglish={setMetaTitleEn}
                      setSpanish={setMetaTitleEs}
                    />
                    <p style={{ marginTop: 20 }}>
                      {i18n.t("Meta Description")}
                    </p>
                    <TranslationTextAreaPair
                      english={metaDescriptionEn}
                      spanish={metaDescriptionEs}
                      setEnglish={setMetaDescriptionEn}
                      setSpanish={setMetaDescriptionEs}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h5 mb-4">
                      <Row>
                        <Col sm={2} className="h5">
                          {i18n.t("Images")}
                        </Col>
                      </Row>
                    </CardTitle>

                    <ImageUploaderMultiple
                      images={images}
                      setImages={setImages}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {i18n.t("Descriptions")}
                    </CardTitle>

                    <p>{i18n.t("Description Title")}</p>

                    <TranslationInputPair
                      english={descriptionTitleEn}
                      spanish={descriptionTitleEs}
                      setEnglish={setDescriptionTitleEn}
                      setSpanish={setDescriptionTitleEs}
                    />

                    <p style={{ marginTop: 20 }}>
                      {i18n.t("Description Subtitle")}
                    </p>

                    <TranslationTextAreaPair
                      english={descriptionSubtitleEn}
                      spanish={descriptionSubtitleEs}
                      setEnglish={setDescriptionSubtitleEn}
                      setSpanish={setDescriptionSubtitleEs}
                    />

                    <p style={{ marginTop: 20 }}>
                      {i18n.t("Description Content")}
                    </p>

                    <TranslationTextAreaPair
                      english={descriptionContentEn}
                      spanish={descriptionContentEs}
                      setEnglish={setDescriptionContentEn}
                      setSpanish={setDescriptionContentEs}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {i18n.t("Location Details")}
                    </CardTitle>

                    <p>{i18n.t("Location Title")}</p>

                    <TranslationInputPair
                      english={locationTitleEn}
                      spanish={locationTitleEs}
                      setEnglish={setLocationTitleEn}
                      setSpanish={setLocationTitleEs}
                    />

                    <p style={{ marginTop: 20 }}>
                      {i18n.t("Location Subtitle")}
                    </p>

                    <TranslationTextAreaPair
                      english={locationSubtitleEn}
                      spanish={locationSubtitleEs}
                      setEnglish={setLocationSubtitleEn}
                      setSpanish={setLocationSubtitleEs}
                    />

                    <p style={{ marginTop: 20 }}>
                      {i18n.t("Location Content")}
                    </p>

                    <TranslationTextAreaPair
                      english={locationContentEn}
                      spanish={locationContentEs}
                      setEnglish={setLocationContentEn}
                      setSpanish={setLocationContentEs}
                    />
                    <p style={{ marginTop: 20 }}>
                      {i18n.t("Location Description")}
                    </p>

                    <TranslationTextAreaPair
                      english={locationDescEn}
                      spanish={locationDescEs}
                      setEnglish={setLocationDescEn}
                      setSpanish={setLocationDescEs}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle>Property Location</CardTitle>
                    <p>Search below for the property location, and/or drag the map pin to the correct location</p>
                    {center && (
                      <GoogleMap
                        center={center}
                        height="600px"
                        zoom={15}
                        setMapAddress={setMapAddress}
                        setLat={setLat}
                        setLng={setLng}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateProperty
