import React, { useState } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
  Input,
} from "reactstrap"
import { getActiveProperties } from "../../helpers/apiRepository"
import PropTypes from "prop-types"
import { useQuery, useMutation, useQueryClient } from "react-query"
import axios from "axios"
import { toast } from "react-toastify"
import i18n from '../../i18n'

const AddModal = ({ modal, toggle }) => {
  const [propertyName, setPropertyName] = useState(i18n.t("Select Property"))
  const [propertyID, setPropertyID] = useState(null)
  const [domainName, setDomain] = useState("")
  const [agentId, setAgentId] = useState(null)

  const queryClient = useQueryClient()

  const properties = useQuery("myProperties", getActiveProperties)

  var token = JSON.parse(localStorage.getItem("authUser")).token
  const mutation = useMutation(body => {
    return axios.post(`${process.env.REACT_APP_API_ROOT}/domains`, body, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
  })

  const data = properties.data
  const activeVal = (val, item) => {
    setPropertyName(val)
    if (item) {
      setPropertyID(item.id)
      setAgentId(item.agentId)
    } else {
      setPropertyID(null)
      setAgentId(null)
    }
  }

  const saveData = () => {
    const details = {
      domain: domainName,
      propertyId: parseInt(propertyID),
      agentId: parseInt(agentId),
      active: true,
    }
    mutation.mutate(details, {
      onSuccess: data => {
        queryClient.invalidateQueries("myDomains")
      },
    })

    mutation.isSuccess &&
      toast.success(i18n.t("Added!"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    mutation.isError &&
      toast.error(i18n.t("Check Details and try again!"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{i18n.t("Add Domain")}</ModalHeader>
        <ModalBody>
          <InputGroup className="input_group">
            <InputGroupAddon addonType="prepend">https://</InputGroupAddon>
            <Input
              placeholder={i18n.t("Domain Name")}
              value={domainName}
              onChange={e => {
                setDomain(e.target.value)
              }}
            />
          </InputGroup>
          <UncontrolledDropdown>
            <DropdownToggle caret className="property_dropdown">
              {propertyName}
            </DropdownToggle>
            <DropdownMenu className="property_dropdown">
              <DropdownItem
                onClick={() => {
                  activeVal("Select Property")
                }}
              >
                {i18n.t("Select Property")}
              </DropdownItem>
              {data &&
                data.map((item, key) => (
                  <DropdownItem
                    key={key}
                    onClick={() => {
                      activeVal(item.descriptions[0].name, item)
                    }}
                  >
                    {item.descriptions[0].name}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              saveData()
              toggle()
            }}
          >
            {i18n.t("Add")}
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
          {i18n.t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

AddModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
}
export default AddModal
