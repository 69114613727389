import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import i18n from "../../../i18n"
import "./standardTemp.css"

const Gallery = props => {
  const data = props.images

  return (
    <section id="gallerySec">
      <div className="container-fluid">
        <h2>{i18n.t("Gallery")}</h2>

        <button
          className="btn btn-info rounded-0 d-md-none mb-3"
          type="button"
          data-toggle="collapse"
          data-target="#galCollapse"
          aria-expanded="false"
          aria-controls="galCollapse"
        >
          {i18n.t("View Gallery")}
        </button>
        <div id="galCollapse" className="collapse dont-collapse-sm mt-2">
          <div id="gallery" className="property-all-images my-masonry-grid">
            {data.map(imageSrc => {
              return (
                <div className="grid-item" key={imageSrc}>
                  <figure className="property-image">
                    <a
                      data-fancybox="gallery"
                      href={
                        process.env.REACT_APP_API_ROOT + "/uploads/" + imageSrc
                      }
                    >
                      <img
                        className="image-fit"
                        src={
                          process.env.REACT_APP_API_ROOT +
                          "/uploads/" +
                          imageSrc
                        }
                      />
                    </a>
                  </figure>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

Gallery.propTypes = {
  images: PropTypes.array,
}

export default Gallery
