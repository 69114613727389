import React from "react"
import "./domains.css"
import MetaTags from "react-meta-tags"
import { Container, Row, Col } from "reactstrap"
import DomainsTable from "./DomainTable"
import { getAllDomains } from "../../helpers/apiRepository"
import { useQuery } from "react-query"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import i18n from '../../i18n'

export default function Domains() {
  const Domains = useQuery("myDomains", getAllDomains)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{i18n.t("Domains | Brily")} ;)</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={i18n.t("Domains")} breadcrumbItem={i18n.t("Domains")} />
          <Row>
            <Col xl="12">
              <DomainsTable domains={Domains} activeOnly={true} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
