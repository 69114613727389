import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import i18n from "../../../i18n"
import "./standardTemp.css"

const PropertyDetails = props => {
  return (
    <section id="details" className="property-details dark">
      <div className="container">
        <h2 className="text-center text-uppercase">{i18n.t("Details")}</h2>
        <ul className="property-icons list-unstyled m-0 mt-5">
          {props.details.map((item, key) => (
            <li className="property-icon" key={key}>
              {item.icon}
              <span>{item.text}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="d-flex justify-content-center align-content-center m-5">
        <a
          className="btn btn-info rounded-0 px-5  py-2 d-flex"
          rel="noreferrer"
          href={process.env.REACT_APP_API_ROOT + "/uploads/" + props.floorPlan}
          target="_blank"
        >
          {i18n.t("Floor Plans")}
        </a>
      </div>
    </section>
  )
}

PropertyDetails.propTypes = {
  floorPlan: PropTypes.string,
  details: PropTypes.array,
}

export default PropertyDetails
