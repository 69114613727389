import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useHistory, withRouter } from "react-router-dom"

const Logout = props => {
  const history = useHistory()

  useEffect(() => {
    localStorage.removeItem("authUser")
    history.push("/login")
  }, [history])

  return <></>
}

export default withRouter(Logout)
