import uk from "../assets/images/flags/uk.jpg"
import spain from "../assets/images/flags/spain.jpg"

const languages = {
  es: {
    label: "Spanish",
    flag: spain,
  },
  en: {
    label: "English",
    flag: uk,
  },
}

export default languages
