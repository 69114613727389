import React from "react"
import PropTypes from "prop-types"
let SectionHTML = ({ id, className, h2, h3, para }) => {
  return (
    <section id={id ? id : ""}>
      <div className={className ? className : ""}>
        {h2 && (
          <h2
            dangerouslySetInnerHTML={{
              __html: h2,
            }}
          ></h2>
        )}
        {h3 && (
          <h3
            dangerouslySetInnerHTML={{
              __html: h3,
            }}
          ></h3>
        )}
        {para && (
          <p
            dangerouslySetInnerHTML={{
              __html: para,
            }}
          ></p>
        )}
      </div>
    </section>
  )
}

SectionHTML.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  h2: PropTypes.any,
  h3: PropTypes.any,
  para: PropTypes.string,
}

export default SectionHTML
