import React, { useState, useEffect } from "react"
import { Container, Row, Col, Button } from "reactstrap"
import AgentsTable from "./AgentsTable"
import { getAllAgents } from "../../helpers/apiRepository"
import i18n from "../../i18n"
import { useQuery } from "react-query"
import "./Agents.css"
import MetaTags from "react-meta-tags"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { Redirect } from "react-router-dom"

const Agents = props => {
  const allAgents = useQuery("allAgents", getAllAgents)
  var user = JSON.parse(localStorage.getItem("authUser"))

  return (
    <React.Fragment>
      {user.isAdmin || user.isAgentAdmin ? (
        <div className="page-content">
          <MetaTags>
            <title>{i18n.t("Agents | Brily")} ;)</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={i18n.t("Agents")}
              breadcrumbItem={i18n.t("All")}
            />
            <Row>
              <Col className="col-10"></Col>
              <Col className="col-2" style={{ textAlign: "right" }}>
                <Button
                  type="button"
                  color="primary"
                  size="sm"
                  className="btn-rounded waves-effect waves-light action-button"
                  style={{ marginBottom: 12, marginTop: 2 }}
                  href="/agents/create"
                >
                  {i18n.t("Add Agents")}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                <AgentsTable agents={allAgents} />
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <Redirect to="/dashboard" />
      )}
    </React.Fragment>
  )
}

export default withTranslation()(Agents)
