import React, {useState, useEffect} from 'react'
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
  } from "reactstrap"


  import PropTypes from 'prop-types'


function Translationinputpair({english, spanish, setEnglish, setSpanish}) {


    const textToEs = () => {

        var details = {
            'source_lang': 'EN',
            'text': english,
            'target_lang': 'ES',
            'auth_key': '1934de98-22bb-2bbf-83af-9682f351d895:fx'
        };

        var formBody = [];
        for (var property in details) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");


        fetch(`https://api-free.deepl.com/v2/translate`, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded'
        }),
        body: formBody

        }

        ).then((items) =>
        {
            return items.json().then(parsedItems => {
                setSpanish(parsedItems.translations[0].text)
            })
        })



    }


    const textToEn = () => {


        var details = {
            'source_lang': 'ES',
            'text': spanish,
            'target_lang': 'EN',
            'auth_key': '1934de98-22bb-2bbf-83af-9682f351d895:fx'
        };

        var formBody = [];
        for (var property in details) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");


        fetch(`https://api-free.deepl.com/v2/translate`, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded'
        }),
        body: formBody

        }

        ).then((items) =>
        {
            return items.json().then(parsedItems => {
                setEnglish(parsedItems.translations[0].text)
            })
        })


    }


    return (
        <>
            <Row>

                <Col md={5}>

                    <Input
                        type="text"
                        id="textarea"
                        maxLength="225"
                        rows="3"
                        placeholder="🇬🇧"
                        value={english}
                        onChange={(val) => setEnglish(val.target.value)}
                    />

                </Col>
                <Col md={2} style={{display: 'flex'}}>
                <Button style={{flex: 1, marginLeft: 5, marginRight: 5}} color="info" onClick={() => textToEn()}>❮</Button>{' '}
                <Button style={{flex: 1, marginLeft: 5, marginRight: 5}} color="info" onClick={() => textToEs()}>❯</Button>{' '}
                </Col>
                <Col md={5}>

                    <Input
                        type="text"
                        id="textarea"
                        maxLength="225"
                        rows="3"
                        placeholder="🇪🇸"
                        value={spanish}
                        onChange={(val) => setSpanish(val.target.value)}

                    />
                </Col>

            </Row>

        </>
    )
}


Translationinputpair.propTypes = {
    english: PropTypes.string,
    spanish: PropTypes.string, 
    setEnglish: PropTypes.func, 
    setSpanish: PropTypes.func
  }

export default Translationinputpair