import React, { useEffect, useState } from "react"
import i18n from "../i18n"
import { useHistory } from "react-router-dom"

import StandardTemplate from "../pages/PropertyTemplates/Standard/StandardTemplate"

export default function Routedomainmatcher(props) {
  const [loading, setLoading] = useState(true)
  const [propertyData, setPropertyData] = useState({})
  const [urlExist, setIsUrlExist] = useState(false)

  let history = useHistory()

  useEffect(() => {
    const host = window.location.hostname

    // we failed all other routes, so we are probably just wanting the dashboard.
    if (host.indexOf("app.brily.dev") > -1) {
      history.push("/dashboard")
    } else {
      console.log("requesting langage " + i18n.language)
      // Check URL in Domain Table
      console.log(host)
      fetch(`${process.env.REACT_APP_API_ROOT}/domain_exists/${host}`).then(
        res =>
          res.json().then(propJson => {
            setIsUrlExist(propJson)
          })
      )
      // }
    }
  }, [])

  useEffect(() => {
    if (urlExist) {
      fetch(
        `${process.env.REACT_APP_API_ROOT}/properties/${urlExist.propertyId}?lang=${i18n.language}`
      ).then(res =>
        res.json().then(propJson => {
          setPropertyData(propJson)
          setLoading(false)
        })
      )
    }
  }, [urlExist])

  return (
    <>{loading == false && <StandardTemplate propertyData={propertyData} />}</>
  )
}
