import React, { useEffect, useState } from "react"
import { MDBDataTable, MDBTableFoot } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, Badge, Button } from "reactstrap"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"

import "./datatables.scss"
import { toast } from "react-toastify"
import { useMutation } from "react-query"
import axios from "axios"
import i18n from "../../i18n"

const ActionButtonsProperty = ({ item }) => {
  let history = useHistory()

  ActionButtonsProperty.propTypes = {
    item: PropTypes.object,
  }
  let id = item.id

  return (
    <>
      <Button
        type="button"
        color="primary"
        size="sm"
        className="btn-rounded waves-effect waves-light action-button"
        style={{ marginbottom: 2, marginTop: 2 }}
        href={`/agents/view/${id}`}
      >
        {i18n.t("View")}
      </Button>

      <Button
        type="button"
        color="warning"
        size="sm"
        className="btn-rounded waves-effect waves-light action-button"
        style={{ marginbottom: 2, marginTop: 2 }}
        href={`/agents/edit/${id}`}
      >
        {i18n.t("Edit")}
      </Button>
    </>
  )
}

const AgentsTable = ({ agents }) => {
  // const dispatch = useDispatch();
  const [stateProperties, setStateProperties] = useState([])
  var user = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {
    let rowData = []
    if (agents.isSuccess) {
      if (user.isAdmin) {
        //Super Admin
        agents.data.map(item => {
          rowData.push({
            full_name: item.firstName + " " + item.lastName,
            email: item.email,
            isVerified: item.isVerified,
            isCompany: item.isAgentAdmin ? "Company" : "Agent",
            createdAt: new Date(item.createdAt).toLocaleDateString(),
            actions: (
              <ActionButtonsProperty item={item}></ActionButtonsProperty>
            ),
          })
        })
        setStateProperties(rowData)
      }
      if (!user.isAdmin && user.isAgentAdmin) {
        //Agent Head Office
        agents.data.map(item => {
          if (user.id == item.agentId && user.id !== item.id) {
            rowData.push({
              full_name: item.firstName + " " + item.lastName,
              email: item.email,
              isVerified: item.isVerified,
              isCompany: "Agent",
              createdAt: new Date(item.createdAt).toLocaleDateString(),
              actions: (
                <ActionButtonsProperty item={item}></ActionButtonsProperty>
              ),
            })
          }
        })
        setStateProperties(rowData)
      }
    }
  }, [agents])

  const data = {
    columns: [
      {
        label: i18n.t("Full Name"),
        field: "full_name",
        width: 150,
      },
      {
        label: i18n.t("Email"),
        field: "email",
        width: 270,
      },
      {
        label: i18n.t("Company/Agent"),
        field: "isCompany",
        width: 200,
      },
      {
        label: i18n.t("Date Added"),
        field: "createdAt",
        sort: "desc",
        width: 100,
      },
      {
        label: i18n.t("Action"),
        field: "actions",
      },
    ],
    rows: stateProperties,
  }

  return (
    <React.Fragment>
      <div>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{i18n.t("Agents")}</CardTitle>
                  <MDBDataTable responsive striped borderless data={data}>
                    <MDBTableFoot> </MDBTableFoot>
                  </MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

AgentsTable.propTypes = {
  agents: PropTypes.object,
}

export default React.memo(AgentsTable)
