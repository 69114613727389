import React from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, CardBody, Card, Container } from "reactstrap"
import { post } from "../../helpers/api_helper"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

import { useMutation } from "react-query"

import { Link } from "react-router-dom"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"
import i18n from "../../i18n"
import { toast } from "react-toastify"

const Register = props => {
  const mutation = useMutation(body => {
    return post(`${process.env.REACT_APP_API_ROOT}/register`, body)
  })
  // handleValidSubmit
  const handleValidSubmit = values => {
    
    values.lang = i18n.language
    mutation.mutate(values, {
      onSuccess: data => {
        if (data.error) {
          toast.error(data.error.code, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.success("User Created Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      },
    })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>{i18n.t("Register | Brily")}</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{i18n.t("Free Register")}</h5>
                        <p>{i18n.t("Get your free Brily account now")}.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <div className="mb-3">
                        <AvField
                          id="email"
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder={i18n.t("Enter email")}
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder={i18n.t("Enter Password")}
                        />
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          {i18n.t("Register")}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                        {i18n.t("By registering you agree to the Brily")}{" "}
                          <Link to="#" className="text-primary">
                            {i18n.t("Terms of Use")}
                          </Link>
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                {i18n.t("Already have an account")} ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    {i18n.t("Login")}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()}{i18n.t("Brily. Crafted with")}{" "}
                  <i className="mdi mdi-heart text-danger" />{i18n.t("by The Brily Team")}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
