import React from "react"
import PropTypes from "prop-types"
import i18n from "../../../i18n"
import Vimeo from "@u-wave/react-vimeo"

function VideoComponent({ data }) { 
  return (
    <>
      {data.videoProvider == "vimeo" && (
        <section id="video" className="dark">
          <div className="container">
            <h2>{i18n.t("VIDEO")}</h2>
            <div className="embed-responsive embed-responsive-16by9">
              <Vimeo
                video={
                  data.videoPath.split("/")[
                    data.videoPath.split("/").length - 1
                  ]
                }
                autoplay
              />
            </div>
          </div>
        </section>
      )}
    </>
  )
}

VideoComponent.propTypes = {
  data: PropTypes.object,
}

export default VideoComponent
