import React, { useEffect, useState } from "react"
import { MDBDataTable, MDBTableFoot } from "mdbreact"
import { Button } from "reactstrap"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import "./datatables.scss"
import i18n from "../../../i18n"

const ActionButtonsProperty = ({ id, removeFeature }) => {
  let history = useHistory()

  ActionButtonsProperty.propTypes = {
    id: PropTypes.number,
    removeFeature: PropTypes.func,
  }

  return (
    <>
      <Button
        type="button"
        color="danger"
        size="sm"
        className="btn-rounded waves-effect waves-light action-button"
        style={{ marginbottom: 2, marginTop: 2 }}
        onClick={e => {
          removeFeature(id)
        }}
      >
        {i18n.t("Delete")}
      </Button>
    </>
  )
}

const FeatureTable = ({ features, removeFeature, fromEdit }) => {
  const [stateFeatures, setStateProperties] = useState([])

  useEffect(() => {
    let rowData = []
    if (features) {
      if (fromEdit == false) {
        features.map((item, key) => {
          rowData.push({
            category: item.category.name_en,
            feature: item.feature.name_en,
            actions: (
              <ActionButtonsProperty
                id={key}
                removeFeature={removeFeature}
              ></ActionButtonsProperty>
            ),
          })
        })
        setStateProperties(rowData)
      }
      if (fromEdit == true) {
        features.map((item, key) => {
          rowData.push({
            category: item.Feature.Category.name_en,
            feature: item.Feature.name_en,
            actions: (
              <ActionButtonsProperty
                id={key}
                removeFeature={removeFeature}
              ></ActionButtonsProperty>
            ),
          })
        })
        setStateProperties(rowData)
      }
    }
  }, [features, fromEdit])

  const data = {
    columns: [
      {
        label: i18n.t("Category"),
        field: "category",
        width: 150,
      },
      {
        label: i18n.t("Feature"),
        field: "feature",
        width: 150,
      },
      {
        label: i18n.t("Action"),
        field: "actions",
      },
    ],
    rows: stateFeatures,
  }

  return (
    <React.Fragment>
      <MDBDataTable
        style={{ marginTop: 10 }}
        responsive
        striped
        borderless
        data={data}
        paging={true}
        displayEntries={false}
        searching={false}
        entries={5}
      >
        <MDBTableFoot></MDBTableFoot>
      </MDBDataTable>
    </React.Fragment>
  )
}

FeatureTable.propTypes = {
  features: PropTypes.array,
  removeFeature: PropTypes.func,
  fromEdit: PropTypes.bool,
}

export default FeatureTable
