import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import i18n from "../../../i18n"
import "./standardTemp.css"

const HeaderTemplate = props => {
  const data = props.propertyData

  return (
    <React.Fragment>
      <link
        href="https://fonts.googleapis.com/css?family=Playfair+Display&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Quicksand:300,500&display=swap"
        rel="stylesheet"
      />

      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
        integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
        crossOrigin="anonymous"
      />

      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css"
      />
      <link rel="stylesheet" href="/templates/standard/owl.carousel.min.css" />
      <link
        rel="stylesheet"
        href="/templates/standard/owl.theme.default.min.css"
      />
      <link rel="stylesheet" href="/templates/standard/styles.css" />

      <header
        className="masthead"
        style={{
          backgroundImage: `url(${
            process.env.REACT_APP_API_ROOT + "/uploads/" + data.images[0]
          })`,
        }}
      >
        <div className="hm-slider-wrap">
          <div className="hm-slider owl-carousel owl-theme" id="hmSlider">
            <div className="hm-slide">
              <img
                data-src={
                  process.env.REACT_APP_API_ROOT + "/uploads/" + data.images[1]
                }
                alt=""
                className="owl-lazy"
              />
            </div>
          </div>

          <div className="hm-slider-tagline">
            <h3
              dangerouslySetInnerHTML={{
                __html: data.descriptions[0].name,
              }}
            ></h3>
          </div>

          <a href="#gallerySec" className="btn btn-view-gallery">
            {i18n.t("View Gallery")}
          </a>
          <a
            href={`https://stories.brily.dev/${data.descriptions[0].name.toLowerCase()}-en.html`}
            target="_blank"
            className="btn btn-view-gallery btn-view-story"
            rel="noreferrer"
          >
            {i18n.t("View Story")}
          </a>
        </div>
      </header>
    </React.Fragment>
  )
}

HeaderTemplate.propTypes = {
  propertyData: PropTypes.object,
}

export default HeaderTemplate
