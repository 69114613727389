import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Button,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  Label,
  Input,
} from "reactstrap"
import i18n from "../../../i18n"
import "./standardTemp.css"

import { useMutation } from "react-query"
import axios from "axios"
import { toast } from "react-toastify"
import { validateEmail } from "helpers/validator"
const ContactForm = props => {
  var userId = 0 //JSON.parse(localStorage.getItem("authUser")).id
  let [statusDropdown, setStatusDropdown] = useState(false)
  let [state, setState] = useState({
    propertyId: props.id,
    agentId: props.agentId,
    name: "",
    email: "",
    telephone: "",
    message: "",
    userId,
  })

  const mutation = useMutation(body => {
    return axios.post(`${process.env.REACT_APP_API_ROOT}/contact`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
  })

  const [ip, setIP] = useState("")

  useEffect(async () => {
    const res = await axios.get("https://geolocation-db.com/json/")
    setIP(res.data.IPv4)
  }, [])
  const submitData = e => {
    e.preventDefault()

    for (let i = 0; i < Object.keys(state).length; i++) {
      const item = Object.keys(state)[i]
      const ele = state[item]
      let errors = [undefined, null, "", " ", "Select Status"]
      if (item == "email") {
        let result = validateEmail(ele)
        if (!result) {
          toast.error(i18n.t(`Write a valid email address`), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          break
        }
      }
      if (errors.includes(ele)) {
        toast.error(`${item.toUpperCase()} can't be empty`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        break
      }
    }

    state.ip = ip.toString()
    // console.log(state);
    mutation.mutate(state, {
      onSuccess: data => {
        toast.success(i18n.t("The Contact Form Has been Submitted!"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        setState({
          ...state,
          name: "",
          email: "",
          telephone: "",
          message: "",
        })
      },
    })
  }

  return (
    <section id="contactForm">
      <div className="container-fluid">
        <h2>{i18n.t("Contact form")}</h2>
        <Container>
          <Row>
            <Col lg={2} className="contactForm"></Col>
            <Col lg={8} className="contactForm">
              <Row>
                <Col lg={4} className="contactForm">
                  <Label
                    className="form-check-Label"
                    htmlFor="formrow-firstname-Input"
                  >
                    {i18n.t("Name")}
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="formrow-firstname-Input"
                    value={state.name}
                    onChange={e => setState({ ...state, name: e.target.value })}
                  />
                </Col>
                <Col lg={4} className="contactForm">
                  <Label className="form-check-Label" htmlFor="for-email">
                    {i18n.t("Email")}
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="for-email"
                    value={state.email}
                    onChange={e =>
                      setState({ ...state, email: e.target.value })
                    }
                  />
                </Col>
                <Col lg={4} className="contactForm">
                  <Label className="form-check-Label" htmlFor="forTelephone">
                    {i18n.t("Telephone")}
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="forTelephone"
                    value={state.telephone}
                    onChange={e => {
                      let regex = /^[a-zA-Z\s]*$/
                      if (regex.test(e.target.value)) {
                        toast.error(i18n.t("Only Numbers allowed"))
                      } else {
                        setState({ ...state, telephone: e.target.value })
                      }
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col lg={2} className="contactForm"></Col>

            <Col lg={8} className="contactForm">
              <Label className="form-check-Label" htmlFor="formrow-customCheck">
                {i18n.t("Message")}
              </Label>
              <Input
                type="textarea"
                className="form-control"
                id="formrow-firstname-Input"
                value={state.message}
                onChange={e => setState({ ...state, message: e.target.value })}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col lg={12} className="contactForm text-center mt-5">
              <Button color="primary" size="lg" onClick={submitData}>
                {i18n.t("Send Query")}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  )
}

ContactForm.propTypes = {
  id: PropTypes.number,
  agentId: PropTypes.number,
}

export default ContactForm
