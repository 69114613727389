import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import { getSingleUser } from "../../helpers/apiRepository"

import i18n from "../../i18n"
import { Link, useParams } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useQuery, useMutation, useQueryClient, queryClient } from "react-query"
import axios from "axios"
import { toast } from "react-toastify"
import { MdOutlineAlternateEmail } from "react-icons/md"

function ViewUser() {
  const id = useParams().id
  const user = JSON.parse(localStorage.getItem("authUser"))
  const [agent, setData] = useState()
  const [notes, setNotes] = useState("")
  const [edit, setEdit] = useState(false)
  const resp = useQuery(["getSingleUser", id], () => getSingleUser(id))
  const statusDetails = ["New", "Active", "Contacted", "Hidden"]

  useEffect(() => {
    if (resp.status == "success") {
      setData(resp.data)
      console.log(resp.data)
    }
  }, [resp])

  const mutation = useMutation(details => {
    return axios.post(`${process.env.REACT_APP_API_ROOT}/contact`, details, {
      headers: {
        "Content-Type": "application/json",
      },
    })
  })

  const saveData = e => {
    e.preventDefault()
    let details = {
      name: agent?.agentName,
      telephone: agent?.agentTelephone,
      email: agent?.agentEmail,
      message: agent?.agentMessage,
      status,
      propertyId: agent?.propertyId,
      agentId: agent?.agentId,
      id: agent?.id,
    }
    if (status !== agent.status) {
      details.notes = `agent status changed from ${lead.status} to ${status}`
    }
    if (notes.length > 0) {
      details.notes = notes
    }

    // console.log(details)

    mutation.mutate(details, {
      onSuccess: data => {
        toast.success("Updated!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        setEdit(false)
      },
      onError: data => {
        toast.error("Check Details and try again!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      },
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{i18n.t("Agents | Brily")} ;)</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={i18n.t("Agents")}
            breadcrumbItem={i18n.t("Contact Detail") + " ID: " + agent?.id}
          />
          <Row>
            <div className="container-fluid">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <CardTitle>
                        <Row>
                          <Col className="col-6 text-capitalize">
                            {agent?.firstName} {agent?.lastName}
                          </Col>
                          {/* {edit && (
                            <Col className="col-6 text-capitalize text-right">
                              <Button
                                type="button"
                                color="success"
                                size="sm"
                                className="btn-rounded waves-effect waves-light action-button"
                                style={{ marginbottom: 2, marginTop: 2 }}
                                onClick={saveData}
                              >
                                Save
                              </Button>
                            </Col>
                          )}
                          {!edit && user.isAdmin && (
                            <Col className="col-6 text-capitalize text-right">
                              <Button
                                type="button"
                                color="success"
                                size="sm"
                                className="btn-rounded waves-effect waves-light action-button"
                                style={{ marginbottom: 2, marginTop: 2 }}
                                onClick={() => {
                                  setEdit(true)
                                }}
                              >
                                Edit
                              </Button>
                            </Col>
                          )} */}
                        </Row>
                      </CardTitle>
                      <Row className="mt-4">
                        <Col xl="4">
                          Email
                          <br />
                          <Link
                            to="#"
                            className="linkedto"
                            onClick={e => {
                              window.location.href = `mailto:${agent?.email}`
                              e.preventDefault()
                            }}
                          >
                            <MdOutlineAlternateEmail size={18} /> {agent?.email}
                          </Link>
                        </Col>
                        <Col xl="4">
                          User Role
                          <br />
                          <b>
                            {agent &&
                              (agent.isAgentAdmin
                                ? "Agency Head Office"
                                : agent.isAdmin
                                ? "Admin"
                                : "Agent")}
                          </b>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col xl="4">
                          Created On
                          <br />{" "}
                          {agent &&
                            new Date(agent.createdAt).toLocaleTimeString(
                              "en-US",
                              {
                                timeZone: "UTC",
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour12: false,
                                hour: "numeric",
                                minute: "numeric",
                              }
                            )}
                        </Col>

                        <Col xl="4">
                          Status
                          <br />
                          <Button
                            type="button"
                            color="success"
                            size="sm"
                            className="btn-rounded waves-effect waves-light action-button"
                            style={{ marginbottom: 2, marginTop: 2 }}
                            onClick={e => {
                              e.preventDefault()
                            }}
                          >
                            {agent?.isVerified ? "Verified" : "Not Verified"}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default React.memo(ViewUser)
