import React, { useEffect, useState } from "react"
import PropTypes, { object } from "prop-types"
import { Row, Col, Button, Label, Input } from "reactstrap"
import "./settings.css"

import { useMutation } from "react-query"
import axios from "axios"
import { toast } from "react-toastify"
import { validateEmail } from "helpers/validator"
import i18n from "../../i18n"

const UpdateSettings = props => {
  let [ImgURL, setUrl] = useState(
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREdLnyaYceRylcLl-pva9E17Q8WVJyrAiXOYompyOZCAaU4-vWXEXHAeiH7odrTCu2tBg&usqp=CAU"
  )

  let [state, setState] = useState({
    name: "",
    password: "",
    logo: "",
  })

  var token = JSON.parse(localStorage.getItem("authUser")).token
  const mutation = useMutation(body => {
    console.log(body)
    return axios.post(`${process.env.REACT_APP_API_ROOT}/agent`, body, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    })
  })

  const submitData = e => {
    e.preventDefault()
    let fd = new FormData()
    let errors = [undefined, null, "", " "]
    for (const item in state) {
      if (!errors.includes(state[item])) {
        fd.append(item, state[item])
      }
    }
    mutation.mutate(fd, {
      onSuccess: data => {
        toast.success(i18n.t("Settings Updated!"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      },
    })
  }

  return (
    <section id="settingsForm" className="mt-4">
      <div className="container-fluid">
        <Row>
          <Label
            className="settings-form-check-Label"
            htmlFor="formrow-customCheck"
          >
            {i18n.t("Logo")}
          </Label>
          <Col lg={8} className="settingsForm-1 ">
            <div className="">
              <Input
                type="file"
                className="form-control"
                id="formrow-imageUpload"
                onChange={e => {
                  let url = URL.createObjectURL(e.target.files[0])
                  setUrl(url)
                  setState({ ...state, logo: e.target.files[0] })
                }}
              />
            </div>
            <div className="">
              <Label className="form-check-Label" htmlFor="formrow-imageUpload">
                <img
                  src={ImgURL}
                  alt=""
                  className="setting_logoImg"
                  title={i18n.t("Change Image")}
                />
              </Label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={8} className="settingsForm">
            <Label className="form-check-Label" htmlFor="formrow-customCheck">
              {i18n.t("Name")}
            </Label>
            <Input
              type="text"
              className="form-control"
              id="formrow-firstname-Input"
              value={state.name}
              onChange={e => setState({ ...state, name: e.target.value })}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col lg={8} className="settingsForm">
            <Label className="form-check-Label" htmlFor="formrow-customCheck">
              {i18n.t("Password")}
            </Label>
            <Input
              type="password"
              className="form-control"
              id="formrow-firstname-Input"
              value={state.password}
              onChange={e => {
                setState({ ...state, password: e.target.value })
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col lg={8} className="settingsForm text-center mt-5">
            <Button color="primary" size="md" onClick={submitData}>
              {i18n.t("Apply Settings")}
            </Button>
          </Col>
        </Row>
      </div>
    </section>
  )
}

UpdateSettings.propTypes = {}

export default UpdateSettings
