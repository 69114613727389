import axios from "axios"
import { del, get, post, put } from "./api_helper"
import i18n from "../i18n"

// Properties

const getActiveProperties = () => {
  return get(
    process.env.REACT_APP_API_ROOT + "/properties?lang=" + i18n.language
  )
}

const getProperty = id => {
  return get(
    process.env.REACT_APP_API_ROOT +
      "/properties/" +
      id +
      "?lang=" +
      i18n.language
  )
}

const importProperty = id => {
  return post(process.env.REACT_APP_API_ROOT + "/importproperty/" + id).then(
    results => {}
  )
}

const getImportedProperties = () => {
  return get(process.env.REACT_APP_API_ROOT + "/imports").then(results => {
    //TODO accept alternative imports and aloow user to choose such

    let importedProps =
      results.length > 0 ? JSON.parse(results[0].importData) : []

    let relayProps = []
    importedProps.map(prope => {
      let thisProp = {}

      thisProp.source = "Realista API"
      thisProp.details = prope.yoast_head_json.title
      thisProp.image = prope.yoast_head_json.og_image[0].url
      thisProp.sourceDate = prope.date
      thisProp.id = prope.id

      relayProps.push(thisProp)
    })

    return relayProps
  })
}

// Domains

const getAllDomains = () => {
  return get(process.env.REACT_APP_API_ROOT + "/domains?lang=" + i18n.language)
}
// Domains

const getAllLeads = () => {
  return get(process.env.REACT_APP_API_ROOT + "/lead")
}
const getFeatures = () => {
  return get(process.env.REACT_APP_API_ROOT + "/features")
}
const getSingleLead = id => {
  return get(process.env.REACT_APP_API_ROOT + "/lead/" + id)
}
const getAllAgents = id => {
  return get(process.env.REACT_APP_API_ROOT + "/agents")
}
const getSingleUser = id => {
  return get(process.env.REACT_APP_API_ROOT + "/agents/" + id)
}
const removeFeature = id => {
  return get(process.env.REACT_APP_API_ROOT + "/remove/feature/" + id)
}


export {
  getActiveProperties,
  getImportedProperties,
  importProperty,
  getProperty,
  getAllDomains,
  getAllLeads,
  getFeatures,
  getSingleLead,
  getAllAgents,
  getSingleUser,
  removeFeature
}
