import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import {
  getActiveProperties,
  getAllDomains,
  getAllLeads,
} from "../../helpers/apiRepository"

//i18n
import { withTranslation } from "react-i18next"
import i18n from "../../i18n"

import { useQuery } from "react-query"

const SidebarContent = props => {
  const propertyCount = useQuery("myProperties", getActiveProperties)
  const myDomains = useQuery("myDomains", getAllDomains)
  const leads = useQuery("allLeads", getAllLeads)
  var user = JSON.parse(localStorage.getItem("authUser"))

  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{i18n.t("Menu")} </li>
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="bx bx-home-smile"></i>
                <span>{i18n.t("Home")}</span>
              </Link>
            </li>
            <li>
              <Link to="/properties" className="waves-effect">
                <i className="bx bx-building-house"></i>

                {propertyCount.isSuccess && (
                  <span className="badge rounded-pill bg-info float-end">
                    {propertyCount.data.length}
                  </span>
                )}
                <span>{i18n.t("Properties")}</span>
              </Link>
            </li>
            <li>
              <Link to="/domains" className="waves-effect">
                <i className="bx bx-link"></i>
                {myDomains.isSuccess && (
                  <span className="badge rounded-pill bg-info float-end">
                    {myDomains.data.length}
                  </span>
                )}
                <span>{i18n.t("Domains")}</span>
              </Link>
            </li>

            {/* <li>
              <Link to="/import" className="waves-effect">
                <i className="bx bx-link"></i>
                <span className="badge rounded-pill bg-info float-end"></span>
                <span>{i18n.t("Import")}</span>
              </Link>
            </li> */}

            {/**
             *  @params isAdmin: Super Admin
             *  @params isAgentAdmin: Company/Agency
             */}
            {(user.isAdmin || user.isAgentAdmin) && (
              <li>
                <Link to="/agents" className="waves-effect">
                  <i className="bx bx-link"></i>
                  <span className="badge rounded-pill bg-info float-end"></span>
                  <span>{i18n.t("Agents")}</span>
                </Link>
              </li>
            )}

            <li>
              <Link to="/leads" className="waves-effect">
                <i className="bx bx-user"></i>
                {leads.isSuccess && (
                  <span className="badge rounded-pill bg-info float-end">
                    {leads.data.length}
                  </span>
                )}
                <span>{i18n.t("Leads")}</span>
              </Link>
            </li>

            <li>
              <Link
                to="/settings"
                className="waves-effect"
                style={{ marginTop: 50 }}
              >
                <i className="bx bx-cog"></i>
                <span className="badge rounded-pill bg-info float-end"></span>
                <span>{i18n.t("Settings")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
