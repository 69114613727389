import React, { useEffect, useState } from "react"
import { MDBDataTable, MDBTableFoot } from "mdbreact"
import { Row, Col, Button } from "reactstrap"
import PropTypes from "prop-types"
import i18n from "../../i18n"

const ActionButtonsProperty = ({ sysString }) => {
  ActionButtonsProperty.propTypes = {
    sysString: PropTypes.string,
  }
  return (
    <>
      {sysString.startsWith("Lead status changed") ? (
        <Button
          type="button"
          color="primary"
          size="sm"
          className="btn-rounded waves-effect waves-light action-button"
          style={{ marginbottom: 2, marginTop: 2 }}
        >
          System 
        </Button>
      ) : (
        <Button
          type="button"
          color="warning"
          size="sm"
          className="btn-rounded waves-effect waves-light action-button"
          style={{ marginbottom: 2, marginTop: 2 }}
        >
          User
        </Button>
      )}
    </>
  )
}
const NotesTable = ({ notes }) => {
  const [leadsData, setLeadsData] = useState([])

  useEffect(() => {
    let rowData = []
    if (typeof notes == "object") {
      if (notes.length > 0) {
        notes.reverse().map(item => {
          rowData.push({
            notes: item?.notes,
            created_at: 
              new Date(item.createdAt).toLocaleTimeString("en-US", {
                timeZone: "UTC",
                year: "numeric",
                month: "short",
                day: "numeric",
                hour12: false,
                hour: "numeric",
                minute: "numeric",
              }),
            generatedBy: <ActionButtonsProperty sysString={item.notes} />,
          })
        })
      }
    }
    setLeadsData(rowData)
  }, [notes])

  const data = {
    columns: [
      {
        label: i18n.t("Date"),
        field: "created_at",
      },
      {
        label: i18n.t("Notes"),
        field: "notes",
      },
      {
        label: i18n.t("By"),
        field: "generatedBy",
      },
    ],
    rows: leadsData,
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <MDBDataTable
            responsive
            striped
            borderless
            data={data}
            searching={false}
            displayEntries={false}
            sortable={false}
            paging={true}
            entries={10}
          >
            <MDBTableFoot> </MDBTableFoot>
          </MDBDataTable>
        </Col>
      </Row>
    </React.Fragment>
  )
}

NotesTable.propTypes = {
  notes: PropTypes.any,
}

export default NotesTable
