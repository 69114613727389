import React, { useEffect, useState } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
  Input,
  Col,
  Row,
} from "reactstrap"

import PropTypes from "prop-types"
import { useQuery, useMutation, useQueryClient } from "react-query"
import axios from "axios"
import { toast } from "react-toastify"
import NotesTable from "./NotesTable"

const AddModal = ({ modal, toggle, itemInModal, isEdit }) => {
  const [status, setStatus] = useState(false)
  const [notes, setNotes] = useState(null)
  const [itemToUpdate, setItemInModal] = useState(null)
  const data = ["New", "Active", "Contacted", "Hidden"]

  const queryClient = useQueryClient()

  useEffect(() => {
    if (itemInModal) {
      if (itemInModal.id) {
        setStatus(itemInModal.status)
        setNotes(itemInModal.leadNotes.length > 0 && "")
        setItemInModal(itemInModal)
      }
    }
  }, [itemInModal])

  const mutation = useMutation(details => {
    return axios.post(`${process.env.REACT_APP_API_ROOT}/contact`, details, {
      headers: {
        "Content-Type": "application/json",
      },
    })
  })

  const activeVal = val => {
    setStatus(val)
  }

  const saveData = e => {
    e.preventDefault()
    const details = {
      name: itemToUpdate.leadName,
      telephone: itemToUpdate.leadTelephone,
      email: itemToUpdate.leadEmail,
      message: itemToUpdate.leadMessage,
      status,
      propertyId: itemToUpdate.propertyId,
      agentId: itemToUpdate.agentId,
      id: itemToUpdate.id,
    }
    if (status !== itemInModal.status) {
      details.notes = `Lead status changed from ${itemInModal.status} to ${status}`
    } else {
      if (notes.length > 0) {
        details.notes = notes
      } else {
        toggle()
        return true
      }
    }

    mutation.mutate(details, {
      onSuccess: data => {
        queryClient.invalidateQueries("allLeads")
      },
    })

    mutation.isSuccess &&
      toast.success("Updated!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    mutation.isError &&
      toast.error("Check Details and try again!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
  }

  return (
    <div>
      <Modal isOpen={modal} className="lead_modal">
        <ModalHeader toggle={toggle}>Edit Lead</ModalHeader>
        <ModalBody>
          <Row>
            <Col xl="6">ID</Col>
            <Col xl="6">Property ID</Col>
            <Col xl="6">
              <InputGroup className="input_group">
                <Input
                  placeholder="ID"
                  value={itemToUpdate?.id}
                  readOnly
                  title="ID"
                />
              </InputGroup>
            </Col>
            <Col xl="6">
              <InputGroup className="input_group">
                <Input
                  placeholder="Property ID"
                  title="Property ID"
                  value={itemToUpdate?.propertyId}
                  readOnly
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xl="6">Agent ID</Col>
            <Col xl="6">Lead Name</Col>
            <Col xl="6">
              <InputGroup className="input_group">
                <Input
                  placeholder="Agent ID"
                  title="Agent ID"
                  value={itemToUpdate?.agentId}
                  readOnly
                />
              </InputGroup>
            </Col>
            <Col xl="6">
              <InputGroup className="input_group">
                <Input
                  placeholder="Lead Name"
                  title="Lead Name"
                  value={itemToUpdate?.leadName}
                  readOnly
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xl="6">TelePhone</Col>
            <Col xl="6">Lead Email</Col>
            <Col xl="6">
              <InputGroup className="input_group">
                <Input
                  placeholder="TelePhone"
                  title="TelePhone"
                  value={itemToUpdate?.leadTelephone}
                  readOnly
                />
              </InputGroup>
            </Col>
            <Col xl="6">
              <InputGroup className="input_group">
                <Input
                  placeholder="Lead Email"
                  title="Lead Email"
                  value={itemToUpdate?.leadEmail}
                  readOnly
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xl="12">Message</Col>
            <Col xl="12">
              <InputGroup className="input_group">
                <Input
                  placeholder="Message"
                  title="Message"
                  value={itemToUpdate?.leadMessage}
                  readOnly
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xl="12">Status</Col>
            <Col xl="12">
              <UncontrolledDropdown className="input_group">
                <DropdownToggle caret className="property_dropdown">
                  {status && status}
                </DropdownToggle>
                <DropdownMenu className="property_dropdown">
                  {data &&
                    data.map((item, key) => {
                      return (
                        <DropdownItem
                          key={key}
                          onClick={() => {
                            activeVal(item)
                          }}
                        >
                          {item}
                        </DropdownItem>
                      )
                    })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
          <Row>
            <Col xl="12">Notes</Col>
            <Col xl="12">
              <InputGroup className="input_group">
                <Input
                  placeholder="Add a new note"
                  title="Notes"
                  value={notes}
                  onChange={e => {
                    setNotes(e.target.value)
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={e => {
              saveData(e)
              toggle()
            }}
          >
            Update
          </Button>

          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

AddModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  isEdit: PropTypes.bool,
  itemInModal: PropTypes.any,
}
export default AddModal
