import React, { useEffect, useState } from "react"
import "./leads.css"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import NotesTable from "./NotesTable"
import { getSingleLead } from "../../helpers/apiRepository"

import i18n from "../../i18n"
import { Link, useParams } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useQuery, useMutation, useQueryClient, queryClient } from "react-query"
import axios from "axios"
import { toast } from "react-toastify"
import { BsInfoCircle } from "react-icons/bs"

function ViewLead() {
  const id = useParams().id
  const user = JSON.parse(localStorage.getItem("authUser"))
  const [lead, setLead] = useState()
  const [notes, setNotes] = useState("")
  const [status, setStatus] = useState(false)
  const [newNote, setNewNote] = useState(false)
  const data = useQuery(["getSingleLead", id], () => getSingleLead(id))
  const statusDetails = ["New", "Active", "Contacted", "Hidden"]

  useEffect(() => {
    if (data.status == "success") {
      setLead(data.data)
      console.log(data.data)
    }
  }, [data])

  const mutation = useMutation(details => {
    return axios.post(`${process.env.REACT_APP_API_ROOT}/contact`, details, {
      headers: {
        "Content-Type": "application/json",
      },
    })
  })

  const saveData = e => {
    e.preventDefault()
    let details = {
      name: lead?.leadName,
      telephone: lead?.leadTelephone,
      email: lead?.leadEmail,
      message: lead?.leadMessage,
      status,
      propertyId: lead?.propertyId,
      agentId: lead?.agentId,
      id: lead?.id,
    }
    if (status !== lead.status) {
      details.notes = `Lead status changed from ${lead.status} to ${status}`
    }
    if (notes.length > 0) {
      details.notes = notes
    }
    if (!details.status) {
      details.status = lead?.status
    }

    mutation.mutate(details, {
      onSuccess: data => {
        toast.success("Updated!")
        setStatus(false)
        setNewNote(false)
      },
      onError: data => {
        toast.error("Check Details and try again!")
      },
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{i18n.t("Leads | Brily")} ;)</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={i18n.t("Leads")}
            breadcrumbItem={i18n.t("Contact Detail") + " ID: " + lead?.id}
          />
          <Row>
            <div className="container-fluid">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <CardTitle>
                        <Row>
                          <Col className="col-6 text-capitalize">
                            {lead?.leadName}{" "}
                          </Col>
                          {status && (
                            <Col className="col-6 text-capitalize text-right">
                              <Button
                                type="button"
                                color="success"
                                size="sm"
                                className="btn-rounded waves-effect waves-light action-button"
                                style={{ marginbottom: 2, marginTop: 2 }}
                                onClick={saveData}
                              >
                                Save
                              </Button>
                            </Col>
                          )}
                          {/* {!status && (user.isAgentAdmin || user.isAdmin) && (
                            <Col className="col-6 text-capitalize text-right">
                              <Button
                                type="button"
                                color="success"
                                size="sm"
                                className="btn-rounded waves-effect waves-light action-button"
                                style={{ marginbottom: 2, marginTop: 2 }}
                                onClick={() => {
                                  setStatus(lead?.status)
                                }}
                              >
                                Edit
                              </Button>
                            </Col>
                          )} */}
                        </Row>
                      </CardTitle>
                      <Row className="mt-4">
                        <Col xl="2">
                          <Link
                            to="#"
                            className="linkedto"
                            onClick={e => {
                              window.location.href = `mailto:${lead?.leadEmail}`
                              e.preventDefault()
                            }}
                          >
                            <i className="bx bx-envelope"></i> {lead?.leadEmail}
                          </Link>
                        </Col>
                        <Col xl="2">
                          <Link
                            to="#"
                            className="linkedto"
                            onClick={e => {
                              window.location.href = `tel:${lead?.leadTelephone}`
                              e.preventDefault()
                            }}
                          >
                            <i className="bx bx-phone"></i>{" "}
                            <b>{lead?.leadTelephone}</b>
                          </Link>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col xl="2">
                          <Link
                            className="linkedto"
                            to={`/property/preview/${lead?.property.id}`}
                          >
                            Property Ref#
                            <br /> {lead?.property.reference}
                          </Link>
                        </Col>
                        <Col xl="2">
                          Agent
                          <br /> {lead?.agent?.name}
                        </Col>
                        <Col xl="2">
                          Status{" "}
                          <BsInfoCircle title="If you're Admin or Agent Head Office then click on the status to change it." />
                          <br />
                          {!status ? (
                            <Button
                              type="button"
                              color="success"
                              size="sm"
                              className="btn-rounded waves-effect waves-light action-button"
                              style={{ marginbottom: 2, marginTop: 2 }}
                              onClick={() => {
                                if (user.isAgentAdmin || user.isAdmin) {
                                  setStatus(lead?.status)
                                }
                              }}
                            >
                              {lead?.status}
                            </Button>
                          ) : (
                            <UncontrolledDropdown className="input_group">
                              <DropdownToggle
                                caret
                                className="property_dropdown"
                              >
                                {status && status}
                              </DropdownToggle>
                              <DropdownMenu className="property_dropdown">
                                {statusDetails &&
                                  statusDetails.map((item, key) => {
                                    return (
                                      <DropdownItem
                                        key={key}
                                        onClick={() => {
                                          setStatus(item)
                                        }}
                                      >
                                        {item}
                                      </DropdownItem>
                                    )
                                  })}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          )}
                        </Col>
                      </Row>

                      <Row className="mt-4"></Row>
                      <Row className="mt-4">
                        <Col xl="12">
                          <b>Message</b>
                        </Col>
                        <Col xl="12" className="mb-2 mt-2">
                          {lead?.leadMessage}
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        {/* {newNote && (
                          <Col className="col-8 text-capitalize">
                            <UncontrolledDropdown className="input_group">
                              <Input
                                placeholder="Write a new note"
                                title="Notes"
                                value={notes}
                                onChange={e => {
                                  setNewNote(e.target.value)
                                }}
                                style={{
                                  width: "75%",
                                  display: "inline-block",
                                  marginRight: 10,
                                }}
                              />
                            </UncontrolledDropdown>
                          </Col>
                        )} */}
                        <Col xl="12">
                          <Row>
                            <Col xl="8" className="col-8 text-capitalize">
                              {newNote && (
                                <UncontrolledDropdown className="input_group">
                                  <Input
                                    placeholder="Write a new note"
                                    title="Notes"
                                    value={notes}
                                    onChange={e => {
                                      setNotes(e.target.value)
                                    }}
                                    style={{
                                      width: "75%",
                                      display: "inline-block",
                                      marginRight: 10,
                                    }}
                                  />
                                </UncontrolledDropdown>
                              )}
                            </Col>
                            <Col xl="4" className="text-right">
                              <Button
                                type="button"
                                color="success"
                                size="sm"
                                className="btn-rounded waves-effect waves-light action-button"
                                style={{ marginbottom: 2, marginTop: 2 }}
                                onClick={e => {
                                  if (newNote) {
                                    saveData(e)
                                  } else {
                                    setNewNote(true)
                                  }
                                }}
                              >
                                {newNote ? "Save" : "Add New Note"}
                              </Button>
                            </Col>
                          </Row>
                          {lead && lead.leadNotes && (
                            <NotesTable notes={lead.leadNotes} />
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewLead
