import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Button,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  Label,
  Input,
} from "reactstrap"
import i18n from "../../../i18n"
import "./sharing.css"

import { useMutation } from "react-query"
import axios from "axios"
import { toast } from "react-toastify"
import { validateEmail } from "helpers/validator"

import { FacebookShareButton, TwitterShareButton } from "react-share"
import { FacebookIcon, TwitterIcon } from "react-share"
import { HiShare } from "react-icons/hi"
const numberWithCommas = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
const SharingComp = ({ item }) => {
  // var user = JSON.parse(localStorage.getItem("authUser"))
  let [statusDropdown, setStatusDropdown] = useState(false)
  let [state, setState] = useState({
    propertyId: 1,
    agentId: 1,
    name: "",
    email: "",
    telephone: "",
    message: "",
  })

  const mutation = useMutation(body => {
    return axios.post(`${process.env.REACT_APP_API_ROOT}/contact`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
  })

  const [ip, setIP] = useState("")

  useEffect(async () => {
    const res = await axios.get("https://geolocation-db.com/json/")
    setIP(res.data.IPv4)
  }, [])
  const [sharabeUrl, setSharableURL] = useState("Domain is not set yet")
  const [desc, setDesc] = useState(false)

  useEffect(() => {
    console.log(item)
    if (item) {
      setDesc(
        `${i18n.t("Stats")}: ${
          item.areaM && `${i18n.t("Area")}: ${item.areaM}`
        } m2, ${item.beds && `${i18n.t("Beds")}: ${item.beds}`}, ${
          item.baths && `${i18n.t("Baths")}: ${item.baths}`
        } \n
        ${i18n.t("Price")}: ${numberWithCommas(item.price)}`
      )

      if (item.Domain) {
        if (item.Domain.length > 0) {
          setSharableURL(`${item.Domain[0].domain}/?sourceId=${user.id}`)
        } else {
          setSharableURL(`${window.location.origin}/property/preview/${item.id}`)
        }
      } else {
        setSharableURL(`${window.location.origin}/property/preview/${item.id}`)
      }
    }
  }, [item])
  return (
    <section id="socialshare_wrp">
      {/* <p className="shareto">Share to</p> */}
      <HiShare size={20} color="#3a3aff" />
      <FacebookShareButton
        url={sharabeUrl}
        // url="https://primeproperty.forsale/"
        quote={desc}
        className="share_btn"
        title="Sharing for testing"
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton
        url={sharabeUrl}
        // url="https://primeproperty.forsale/"
        quote={desc}
        className="share_btn"
        title="Sharing for testing"
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>
    </section>
  )
}

SharingComp.propTypes = {
  item: PropTypes.any,
  agentId: PropTypes.number,
}

export default SharingComp
