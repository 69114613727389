import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// App Functional Pages
import Dashboard from "../pages/Dashboard/index"
import Properties from "../pages/Properties/index"
import PropertyPreview from "../pages/PreviewProperty/PreviewProperty"
import EditProperty from "../pages/EditProperty/EditProperty"
import CreateProperty from "../pages/CreateProperty/CreateProperty"
import ImportPage from "../pages/ImportPage/"
import DomainsPage from "../pages/Domains/Domains"
import LeadsPage from "../pages/Leads/Leads"
import ViewLead from "../pages/Leads/ViewLead"
import SettingsPage from "../pages/Settings/Settings"
import Agents from "../pages/Agents/index"
import ViewUser from "../pages/Agents/ViewUser"
import AddAgent from "../pages/Agents/AddAgent"
import EditAgent from "../pages/Agents/EditAgent"

const authProtectedRoutes = [
  // { path: "/", component: Dashboard },
  { path: "/dashboard", component: Dashboard },
  { path: "/properties", component: Properties },
  { path: "/property/edit/:id/", component: EditProperty, exact: true },
  { path: "/property/create/", component: CreateProperty, exact: true },
  { path: "/import", component: ImportPage },
  { path: "/domains", component: DomainsPage },
  { path: "/leads", component: LeadsPage },
  { path: "/leads/view/:id", component: ViewLead },
  { path: "/settings", component: SettingsPage },
  { path: "/agents", component: Agents },
  { path: "/agents/view/:id", component: ViewUser },
  { path: "/agents/create", component: AddAgent },
  { path: "/agents/edit/:id", component: EditAgent },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  // { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/property/preview/:id", component: PropertyPreview },
]

export { publicRoutes, authProtectedRoutes }
