import React from "react"
import "./settings.css"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import UpdateSettings from "./UpdateSettings"
import i18n from "../../i18n"

function Settings() {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{i18n.t("Settings | Brily")} ;)</title>
        </MetaTags>
        <Container fluid>
          <h4>{i18n.t("Settings")}</h4>
          <UpdateSettings />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Settings
