import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'; 

import { useQuery } from 'react-query';
import { getProperty } from '../../helpers/apiRepository';
import StandardTemplate from '../PropertyTemplates/Standard/StandardTemplate';
 
export default function PreviewProperty(props) {


    let { id } = useParams();

    const property = useQuery(['property', id], () => getProperty(id))

    return (
        <>
        {property.data &&  (
            <StandardTemplate propertyData={property.data}/>  
        )}
        </>
    )
}

