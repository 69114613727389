import i18n from "./i18n"
import axios from "axios"

const fetchProperties = (page, limit, active) => {
  var token = JSON.parse(localStorage.getItem("authUser")).token
  console.log(`using token ${token}`)

  return fetch(
    `${process.env.REACT_APP_API_ROOT}/properties?lang=${i18n.language}`,
    {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      }),
    }
  ).then(items => {
    return items.json().then(parsedItems => {
      return parsedItems
    })
  })
}

const getProperty = propertyId => {
  var token = JSON.parse(localStorage.getItem("authUser")).token
  console.log(`using token ${token}`)

  return fetch(
    `${process.env.REACT_APP_API_ROOT}/properties/${propertyId}?lang=${i18n.language}`,
    {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      }),
    }
  ).then(items => {
    return items.json().then(parsedItems => {
      return parsedItems
    })
  })
}

const saveDomain = body => {
  var token = JSON.parse(localStorage.getItem("authUser")).token
  console.log(`using token ${token}`)

  return fetch(`${process.env.REACT_APP_API_ROOT}/domains`, {
    method: "post",
    headers: new Headers({
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(body),
  })
    .then(result => {
      return result
    })
    .catch(error => {
      console.log(error)
      return error
    })
}

export { fetchProperties, getProperty, saveDomain }
