import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import VideoComponent from "../Common/VideoComponent"
import "./standardTemp.css"
import {
  MdKingBed,
  MdBathtub,
  MdEuroSymbol,
  MdFullscreen,
} from "react-icons/md"
import { FaHome } from "react-icons/fa"
import SectionHTML from "../Common/sectionHTML"
import HeaderTemplate from "./Header"
import ContactForm from "./ContactForm"
import Gallery from "./Gallery"
import Map from "./Map"
import PropertyDetails from "./propertyDetails"
import Sharing from "./Sharing"
//i18n
import { withTranslation } from "react-i18next"
import i18n from "../../../i18n"

const StandardTemplate = props => {
  const data = props.propertyData

  useEffect(() => {
    if (data) {
      setTimeout(window.loadAllOldJs, 1000)
      document.title = props.propertyData.descriptions[0].name
      console.log(data)
    }
  }, [data])

  let [details, setDetails] = useState([])
  useEffect(() => {
    if (data) {
      setDetails([
        {
          icon: <MdKingBed size={40} />,
          text: `${data.beds} ${i18n.t("Bedrooms")}`,
        },
        {
          icon: <MdBathtub size={40} />,
          text: `${data.baths} ${i18n.t("Bathrooms")}`,
        },
        {
          icon: <FaHome size={40} />,
          text: `${data.areaM} m²`,
        },
        {
          icon: <MdFullscreen size={40} />,
          text: `${data.plot} m²`,
        },
        {
          icon: <MdEuroSymbol size={40} />,
          text: `${data.price.toLocaleString()}`,
        },
      ])
    }
  }, [data])
  return (
    <div>
      {data && data.descriptions && (
        <>
          <main id="top">
            <HeaderTemplate propertyData={data} />
            <SectionHTML
              id="intro"
              className="container"
              h2={data.descriptions[0].introTitle}
              h3={data.descriptions[0].introSubtitle}
              para={data.descriptions[0].introContent}
            />
            {details?.length > 0 && (
              <PropertyDetails details={details} floorPlan={data.floorPlan} />
            )}

            <SectionHTML
              id="about"
              className="container"
              h2={data.descriptions[0].descriptionTitle}
              h3={data.descriptions[0].descriptionSubtitle}
              para={data.descriptions[0].descriptionContent}
            />

            <section className="full-image dark">
              <img
                className="w-100"
                src={
                  process.env.REACT_APP_API_ROOT + "/uploads/" + data.images[1]
                }
              />
            </section>

            <VideoComponent data={data} />
            {data.images?.length > 0 && <Gallery images={data.images} />}

            <ContactForm id={data.id} agentId={data.agentId} />

            <SectionHTML
              className="container"
              h2={i18n.t("LOCATION")}
              h3={data.descriptions[0].locationSubtitle}
              para={data.descriptions[0].locationContent}
            />

            {(data.latitude || data.longitude || data.mapAddress) && (
              <Map
                latitude={data.latitude}
                longitude={data.longitude}
                mapAddres={data.mapAddress}
              />
            )}
          </main>

          <footer id="footer" className="mt-4">
            <div className="footer-bottom text-muted">
              <div className="container">
                <p className="text-center">
                  © 2021 | {i18n.t("Website design by")}
                  <a
                    style={{ whiteSpace: "nowrap" }}
                    rel="noreferrer"
                    href="https://grasp.marketing/"
                    target="_blank"
                  >
                    {i18n.t("Grasp Marketing")}
                  </a>
                </p>
              </div>
            </div>
          </footer>
          {data && <Sharing item={data} />}
        </>
      )}
    </div>
  )
}

StandardTemplate.propTypes = {
  propertyData: PropTypes.object,
}

export default withTranslation()(StandardTemplate)
