import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Col,
  Row,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
} from "reactstrap"
import FeatureTable from "./FeatureTable"
import { useQuery } from "react-query"
import { getFeatures, removeFeature } from "../../../helpers/apiRepository"
import i18n from "../../../i18n"
import { useMutation, useQueryClient } from "react-query"
import axios from "axios"
export default function PropertyFeatures({
  features,
  updateFeatures,
  fromEdit,
}) {
  const [isCatOpen, setIsCatOpen] = useState(false)
  const [featureOpen, setIsFeatureOpen] = useState(false)
  const [featuresVal, setFeatures] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState("Select Category")
  const [selectedFeature, setSelectedFeature] = useState("Select Category")
  const [featureArrry, setFeatureArrr] = useState([])

  const featuresArr = useQuery("features", () => getFeatures())

  var token = JSON.parse(localStorage.getItem("authUser")).token
  const mutation = useMutation(body => {
    return axios.get(
      `${process.env.REACT_APP_API_ROOT}/remove/feature/${body.id}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      }
    )
  })

  useEffect(() => {
    if (featuresArr.status === "success") {
      setFeatureArrr(featuresArr.data)
    }
  }, [featuresArr])

  const removeFeature = id => {
    let remaintItems = features.filter((item, key) => key !== id)
    let deletedItems = features.filter((item, key) => key === id)

    mutation.mutate(
      { id: deletedItems[0].id },
      {
        onSuccess: data => {
          console.log(data)
        },
      }
    )
    updateFeatures([...remaintItems])
  }

  return (
    <>
      <Row>
        <Row>
          <Col md={6}>
            <Label htmlFor="formrow-password-Input" style={{ paddingTop: 9 }}>
              {i18n.t("Category")}
            </Label>
            <br />
          </Col>
          <Col md={6}>
            <Label htmlFor="formrow-password-Input" style={{ paddingTop: 9 }}>
              {i18n.t("Features")}
            </Label>
            <br />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <ButtonDropdown
              isOpen={isCatOpen}
              toggle={() => setIsCatOpen(!isCatOpen)}
            >
              <Button id="caret" color="secondary" style={{ width: 150 }}>
                {selectedCategory.name_en
                  ? selectedCategory.name_en
                  : selectedCategory}
              </Button>
              <DropdownToggle caret color="secondary">
                <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>{i18n.t("Category")}</DropdownItem>
                {featureArrry.length > 0 &&
                  featureArrry.map((item, key) => (
                    <DropdownItem
                      key={key}
                      onClick={() => {
                        setSelectedCategory(item)
                        setFeatures(item.Feature)
                        setSelectedFeature(false)
                      }}
                    >
                      {item.name_en}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
          <Col md={6}>
            <ButtonDropdown
              isOpen={featureOpen}
              toggle={() => setIsFeatureOpen(!featureOpen)}
            >
              <Button id="caret" color="secondary" style={{ width: 150 }}>
                {selectedFeature.name_en
                  ? selectedFeature.name_en
                  : selectedFeature}
              </Button>
              <DropdownToggle caret color="secondary">
                <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu>
                {featuresVal &&
                  featuresVal.map((item, key) => (
                    <DropdownItem
                      key={key}
                      onClick={() => {
                        setSelectedFeature(item)
                      }}
                    >
                      {item.name_en}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
        </Row>
      </Row>

      <Row className="text-right">
        <Col md={12}>
          <Button
          style={{marginTop:10}}
            color="success"
            onClick={e => {
              e.preventDefault()
              updateFeatures([
                ...features,
                {
                  category: selectedCategory,
                  feature: selectedFeature,
                },
              ])
            }}
          >
            {i18n.t("Add")}
          </Button>
        </Col>
      </Row>

      <Row>
        <FeatureTable
          features={features}
          removeFeature={removeFeature}
          fromEdit={fromEdit}
        ></FeatureTable>
      </Row>
    </>
  )
}

PropertyFeatures.propTypes = {
  features: PropTypes.array,
  updateFeatures: PropTypes.func,
  fromEdit: PropTypes.bool,
}
