import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import i18n from "../../../i18n"
import "./standardTemp.css"

const Map = props => {

  return (
    <section id="map" className="container-fluid p-0 dark">
      <div className="map-responsive">
        <iframe
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src={`https://maps.google.com/maps?q=${props.latitude},${props.longitude}&t=m&z=14&output=embed&iwloc=near`}
          aria-label={props.mapAddress}
          frameBorder="0"
        ></iframe>
      </div>
    </section>
  )
}

Map.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  mapAddress: PropTypes.string,
}

export default Map
