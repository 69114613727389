import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Form,
  Input,
  Button,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap"
import i18n from "../../i18n"
import { useMutation, useQuery } from "react-query"
import axios from "axios"
import { toast } from "react-toastify"
import { types } from "helpers/validator"
import { getSingleUser } from "../../helpers/apiRepository"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"

function EditAgent() {
  const id = useParams().id
  const resp = useQuery(["getSingleUser", id], () => getSingleUser(id))
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const [selectedRole, setRole] = useState(i18n.t("Select Provider"))
  const [agent, setData] = useState()

  useEffect(() => {
    if (resp && resp != undefined && resp.isSuccess && resp.data) {
      setData(resp.data)
      let data = resp.data
      setFirstName(data.firstName)
      setLastName(data.lastName)
      setEmail(data.email)
      if (data.isAgentAdmin) {
        setRole("Agent Head Office")
      } else {
        setRole("Agent")
      }
    }
  }, [resp.isSuccess])
  var token = JSON.parse(localStorage.getItem("authUser")).token
  const mutation = useMutation(body => {
    return axios.post(`${process.env.REACT_APP_API_ROOT}/update/user`, body, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
  })

  const submit = e => {
    e.preventDefault()
    let details = {
      firstName,
      lastName,
      email,
      password,
      isAgentAdmin: selectedRole == "AgentHeadOffice" ? true : false,
      lang: i18n.language,
      newPassword,
    }

    mutation.mutate(details, {
      onSuccess: data => {
        console.log(data)
        if (data.status == 200) {
          toast.success(i18n.t("Update Successfully!"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
        if (data.data.error) {
          toast.error(data.data.error.msg || data.data.error.code, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      },
      onError: data => {
        console.log(data)
        toast.error("Internal server error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      },
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{i18n.t("Edit User | Brily")} ;)</title>
        </MetaTags>
        <Container fluid>
          <>
            <Breadcrumbs
              title={i18n.t("Agents")}
              breadcrumbItem={i18n.t("Edit")}
            />
            <Row>
              <Col lg={3}></Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {i18n.t("User Details")}
                    </CardTitle>

                    <Form>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="forFirstName">
                              {i18n.t("First Name")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="forFirstName"
                              value={firstName}
                              onChange={e => setFirstName(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="forLastName">
                              {i18n.t("Last Name")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="forLastName"
                              value={lastName}
                              onChange={e => setLastName(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="mb-3">
                        <Label htmlFor="forEmail">{i18n.t("Email")}</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="forEmail"
                          value={email}
                          readOnly
                          onChange={e => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="forPassword">
                          {i18n.t("Old Password")}
                        </Label>
                        <Input
                          type="password"
                          className="form-control"
                          id="forPassword"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="forNewPassword">
                          {i18n.t("New Password")}
                        </Label>
                        <Input
                          type="password"
                          className="form-control"
                          id="forNewPassword"
                          value={newPassword}
                          onChange={e => setNewPassword(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <Label>{i18n.t("Select Role")}</Label>
                        <br />
                        <ButtonDropdown
                          isOpen={isDropDownOpen}
                          toggle={() => setIsDropDownOpen(!isDropDownOpen)}
                          onClick={e => e.preventDefault()}
                          style={{
                            width: "100%",
                          }}
                        >
                          <Button
                            id="caret"
                            color="secondary"
                            style={{ width: "85%", textAlign: "left" }}
                          >
                            {selectedRole}
                          </Button>
                          <DropdownToggle caret color="secondary">
                            <i className="mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu
                            style={{
                              width: "100%",
                            }}
                          >
                            <DropdownItem
                              onClick={e => setRole("Agent Head Office")}
                            >
                              {i18n.t("Agent Head Office")}
                            </DropdownItem>
                            <DropdownItem onClick={e => setRole("Agent")}>
                              {i18n.t("Agent")}
                            </DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      </div>

                      <div className="mb-3 text-right">
                        <Button
                          color="danger"
                          style={{ flex: 1, marginLeft: 5, marginRight: 5 }}
                        >
                          {i18n.t("Cancel")}
                        </Button>
                        <Button
                          color="success"
                          style={{ flex: 1, marginLeft: 5, marginRight: 5 }}
                          onClick={submit}
                        >
                          {i18n.t("Create Now")}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={3}></Col>
            </Row>
          </>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditAgent
